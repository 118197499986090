// imports the React Javascript Library
import React, { useState, useRef } from 'react';
import { Fab, withStyles } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import { CameraAltOutlined as EditIcon } from '@material-ui/icons';
import ImageCropper from './ImageCropper';

const styles = () => ({
  root: {
    position: 'relative',
    borderRadius: 50,
    marginBottom: 20,
  },
  input: {
    display: 'none',
  },
  media: {
    width: '100px',
    height: '100px',
    borderRadius: 50,
  },
  editFab: {
    position: 'absolute',
    top: 55,
    right: 95,
    margin: 10,
    width: '35px',
    height: '35px',
  },
});

const ImageUploadCard = ({
  classes,
  initialImage,
  onFileSelectError,
  onFileSelectSuccess,
}) => {
  const [showUploaderModal, setShowUploaderModal] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const fileRef = useRef(null);

  const closeUploadModal = () => setShowUploaderModal(false);
  const openUploadModal = () => setShowUploaderModal(true);

  const handleUploadClick = (event) => {
    fileRef.current = event.target.files[0];

    if (fileRef.current?.size > 1024 * 1000)
      onFileSelectError({ error: 'File size cannot exceed more than 1MB' });

    const reader = new FileReader();

    reader.onloadend = function(e) {
      openUploadModal();
      const image = reader.result;
      setImageToCrop(image);
    };

    reader.readAsDataURL(fileRef.current);
  };

  const onSave = async () => {
    let file = null;

    if (!croppedImage) file = fileRef.current;
    else {
      let blob = await fetch(croppedImage).then((r) => r.blob());

      file = new File([blob], 'profile-image.jpg', {
        lastModified: new Date().getTime(),
        type: 'image/jpeg',
      });
    }

    onFileSelectSuccess(file);
    closeUploadModal();
  };

  return (
    <div className={classes.root}>
      <img
        width='100%'
        className={classes.media}
        alt='alt'
        src={initialImage}
      />
      <input
        accept='image/*'
        className={classes.input}
        id='contained-button-file'
        multiple
        type='file'
        onChange={handleUploadClick}
      />
      <label htmlFor='contained-button-file'>
        <Fab className={classes.editFab} component='span'>
          <EditIcon />
        </Fab>
      </label>

      <Modal show={showUploaderModal} onHide={closeUploadModal}>
        <div className='uploader__modal'>
          <div className='uploader__modal__title'>Set Profile Photo</div>
          <p className='uploader__modal__text'>
            To make a crop selection, hold down the left mouse button and drag a
            rectangle across the image
          </p>
          <div className='uploader__modal__wrapper'>
            <div className='uploader__modal__previewer'>
              <ImageCropper
                imageToCrop={imageToCrop}
                onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
              />
            </div>
          </div>
          <div className='uploader__modal__footer'>
            <button
              className='uploader__modal__btn uploader__modal__btn--cancel'
              onClick={closeUploadModal}
            >
              Cancel
            </button>
            <button
              className='uploader__modal__btn uploader__modal__btn--save'
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ImageUploadCard);
