import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_PREVIEW_CARD_REQUEST,
  PREVIEW_CARD_REQUEST_SUCCESS,
  PREVIEW_CARD_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* previewECardWatcher() {
  yield takeLatest(START_PREVIEW_CARD_REQUEST, previewECardWorker);
}

function previewECard(payload) {
  return axios.post("api/preview-ecard", payload);
}

function* previewECardWorker(action) {
  try {
    const response = yield call(previewECard, action.payload);
    const data = response.data;

    yield put({ type: PREVIEW_CARD_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: PREVIEW_CARD_REQUEST_FAILED, error });
  }
}
