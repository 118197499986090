import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { addMediaAction } from '../../../../redux/actions/Media/addMedia';
import VideoRecorderComponent from './VideoRecorder';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faCamera,
  faCameraRetro,
  faPhotoVideo,
} from '@fortawesome/free-solid-svg-icons';
import CustomFileUploader from './CustomFileUploader';
import './add-media.scss';

const AddMedia = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formData = new FormData();
  const videoFileRef = useRef(null);

  const [showVideoRecModal, setShowVideoRecModal] = useState(false);
  const [showVideoNameDialogue, setShowVideoNameDialogue] = useState(false);
  const [videoName, setVideoName] = useState('');
  const closeVideoRecModal = () => setShowVideoRecModal(false);
  const openVideoRecModal = () => setShowVideoRecModal(true);

  const closeVideoNameDialogue = () => {
    setVideoName('');
    setShowVideoNameDialogue(false);
  };

  const openVideoNameDialogue = () => {
    setVideoName('');
    setShowVideoNameDialogue(true);
  };

  const redirectCallback = () => history.push('/media-library');

  const submitVideo = () => {
    if (videoName) {
      formData.append('fileName', videoName);
      formData.append('file', videoFileRef.current);
      closeVideoNameDialogue();

      dispatch(
        addMediaAction({
          data: formData,
          callback: redirectCallback,
        })
      );
    } else if (!videoName) {
      toast.error('Cannot save video without name');
    }
  };

  const onSuccess = (file, type) => {
    formData.append('file', file);
    file?.type && formData.append('type', file.type);

    if (type !== 'video') {
      dispatch(
        addMediaAction({
          data: formData,
          callback: redirectCallback,
        })
      );
    } else if (type === 'video') {
      videoFileRef.current = file;
      openVideoNameDialogue();
    }
  };

  return (
    <div className='add-media'>
      <div className='add-media__options'>
        <div className='add-media__options__heading'>
          <h3>Add media files</h3>
          <h5>
            Please select a method to add a new media library file. These files
            can be managed and used to schedule Wyshes
          </h5>
        </div>
        <ul className='add-media__options__list'>
          <li>
            <CustomFileUploader
              type='video'
              accept='accept="video/mp4,video/x-m4v,video/*'
              icon={faCamera}
              onFileSelectSuccess={onSuccess}
            />
            <span>Upload Video</span>
          </li>
          <li>
            <div className='custom-file-uploader'>
              <button
                onClick={openVideoRecModal}
                className='custom-file-uploader__button'
              >
                <i className='custom-file-uploader__icon'>
                  <FontAwesomeIcon icon={faCameraRetro} />
                </i>
              </button>
            </div>
            <span>Record Video</span>
          </li>
          <li>
            <CustomFileUploader
              type='image'
              accept='accept="image/jpg,image/jpeg,image/png,image/*'
              icon={faPhotoVideo}
              onFileSelectSuccess={onSuccess}
            />
            <span>Upload Photo</span>
          </li>
          <li>
            <CustomFileUploader
              // type='file'
              // accept='accept="image/jpg,image/jpeg,image/png,image/*'
              icon={faFile}
              onFileSelectSuccess={onSuccess}
            />
            <span>Upload File</span>
          </li>
        </ul>
      </div>
      {/* <button
        className='add-media__cancel-button'
        onClick={() => history.push('/media-library')}
      >
        Cancel
      </button> */}

      <Modal show={showVideoRecModal} onHide={closeVideoRecModal}>
        <Modal.Header closeButton>
          <Modal.Title>Record a video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VideoRecorderComponent onSuccess={onSuccess} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`btn btn-primary font-weight-bold px-3 py-4 my-3`}
            onClick={closeVideoRecModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showVideoNameDialogue} onHide={closeVideoNameDialogue}>
        <div className='video-name-dialogue'>
          <span
            onClick={closeVideoNameDialogue}
            className='video-name-dialogue__close'
          >
            x
          </span>
          <input
            type='text'
            placeholder='Please enter video name'
            value={videoName}
            onChange={(e) => setVideoName(e.target.value)}
            className='video-name-dialogue__input'
          />
          <button className='video-name-dialogue__button' onClick={submitVideo}>
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddMedia;
