import React from 'react';
import { useHistory } from 'react-router-dom';
import MediaCard from './MediaCard';
import './media-gallery.scss';
import AddMedia from '../AddMedia/AddMedia';
import { Tab, Tabs } from 'react-bootstrap';

const MEDIA_TYPES = {
  DOCUMENT: 'document',
  IMAGE: 'image',
  VIDEO: 'video',
  UN_CATEGORIZED: 'not found',
}
// categories

const MediaGalley = ({ media }) => {
  const history = useHistory();

  const renderMediaCards = (mediaType) => {
    return (
      <div className='media-gallery__media-list'>
        {media?.data?.entityList?.length ? (
          media.data.entityList.map((item, i) => (
            item.Type === mediaType? <MediaCard key={i} item={item} /> : null
          ))
        ) : (
          <div className='media-gallery__no-media'>
            <img
              src='./media/png/media-library-empty.png'
              className='media-gallery__no-media__img'
              alt=''
            />
            <h2 className='media-gallery__no-media__heading'>No Files</h2>
            <p className='media-gallery__no-media__text'>
              Use the "Add Media" button to rrdeco or upload files
            </p>
            <p className='media-gallery__no-media__text'>
              to your media library. Your files will appear here.
            </p>
          </div>
        )}
      </div>
    );
  };

  return (

    <div className='media-gallery'>
      {/* <button
        className='media-gallery__add-media-button'
        onClick={() => history.push('/add-media')}
      >
        Add Media
      </button> */}
      <AddMedia />
      <Tabs
        defaultActiveKey="images"
        id="noanim-tab-example"
        className="media-tabs"
      >
        <Tab eventKey="images" title="Images">
          {renderMediaCards(MEDIA_TYPES.IMAGE)}
        </Tab>
        <Tab eventKey="videos" title="Videos">
          {renderMediaCards(MEDIA_TYPES.VIDEO)}

        </Tab>
        <Tab eventKey="documents" title="Documents">
          {renderMediaCards(MEDIA_TYPES.DOCUMENT)}
          {renderMediaCards(MEDIA_TYPES.UN_CATEGORIZED)}
        </Tab>
      </Tabs>
    </div>
  );
};

export default MediaGalley;
