import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import {
  START_EDIT_BENEFECIARY_REQUEST,
  EDIT_BENEFECIARY_REQUEST_SUCCESS,
  EDIT_BENEFECIARY_REQUEST_FAILED,
} from "../../actions/actionTypes";
import swal from "sweetalert";

export function* editBeneficiaryWatcher() {
  yield takeLatest(START_EDIT_BENEFECIARY_REQUEST, editBeneficiaryWorker);
}

function editBeneficiary(data) {
  return axios.put("api/beneficiaries/update", data);
}

function* editBeneficiaryWorker(action) {
  try {
    const response = yield call(editBeneficiary, action.payload);
    const data = response.data;

    yield put({ type: EDIT_BENEFECIARY_REQUEST_SUCCESS, data });

    if (data?.code === 200) {
      swal("Beneficiary!", "Updated successfully!").then(() => {
        window.location.pathname = "/beneficiaries";
      });
    }
  } catch (error) {
    yield put({ type: EDIT_BENEFECIARY_REQUEST_FAILED, error });
  }
}
