/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { getLocalState } from '../../../../../helpers/localStorage';
import ImageUploadCard from '../../../../_partials/controls/forms/ImageUploader';
import { changeProfilePhotoAction } from '../../../../../redux/actions/changeProfilePhoto';
import './user-profile-dropdown.scss';

export function UserProfileDropdown() {
  const dispatch = useDispatch();
  const user = getLocalState('@user');

  const logout = () => {
    localStorage.clear('@token');
    localStorage.clear('@user');
    localStorage.clear('@register');
    window.location.pathname = '/';
  };

  const items = [
    {
      title: 'Update Profile',
      to: '/user-profile',
    },
    {
      title: 'Change Password',
      to: '/change-pass',
    },
    {
      title: 'Upgrade Plan',
      to: '/plans',
    },
  ];

  const onFileSelectSuccess = async (file) => {
    console.log(file);
    const formData = new FormData();
    formData.append('Image', file);

    dispatch(changeProfilePhotoAction(formData));
  };

  const onFileSelectError = (error) => {
    alert(error);
  };

  const NavItem = ({ to, title }) => (
    <Link to={to} className='navi-item px-8 cursor-pointer'>
      {title}
    </Link>
  );

  return (
    <Dropdown drop='down' alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id='dropdown-toggle-user-profile'
      >
        <div
          className={
            'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'
          }
        >
          <span className='user-profile-dropdown-toggle__avatar-frame'>
            <img
              src={user?.Image}
              alt='user-profile'
              className='user-profile-dropdown-toggle__avatar'
            />
          </span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className='user-profile-dropdown-menu dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround'>
        <div className='user-profile-dropdown-menu__wrapper'>
          <div>
            <div className='user-profile-dropdown-menu'>
              <div className='user-profile-dropdown-menu__info'>
                <ImageUploadCard
                  initialImage={user?.Image}
                  onFileSelectError={onFileSelectError}
                  onFileSelectSuccess={onFileSelectSuccess}
                />
                <div className='user-profile-dropdown-menu__info__username'>{`${user?.FirstName} ${user?.LastName}`}</div>
                <div>{user?.Email}</div>
              </div>
              <div className='user-profile-dropdown-menu__nav-list'>
                {items.map(({ title, to }) => (
                  <div
                    key={title}
                    className='user-profile-dropdown-menu__nav-item'
                  >
                    <NavItem title={title} to={to} />
                  </div>
                ))}
              </div>
              <div className='user-profile-dropdown__logout' onClick={logout}>
                Logout
              </div>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
