import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './aside-menu-item.scss';

export function AsideMenuItem({ active, icon, title, link }) {
  return (
    <li
      className={`aside-menu-item ${active ? 'aside-menu-item--active' : ''}`}
      aria-haspopup='true'
    >
      <NavLink className='aside-menu-item__link' to={link}>
        <span className='aside-menu-item__icon'>
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className='aside-menu-item__text'>{title}</span>
      </NavLink>
    </li>
  );
}
