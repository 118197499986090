import React, { useEffect, useState } from 'react';
import { ReactMediaLibrary } from 'react-media-library';
import { useDispatch, useSelector } from 'react-redux';
import { addMediaAction } from '../../../redux/actions/Media/addMedia';
import { getMediaAction } from '../../../redux/actions/Media/getMedia';
import { deleteMediaAction } from '../../../redux/actions/Media/deleteMedia';
import { dataURItoBlob } from '../../../helpers/dataURItoBlob';
import swal from 'sweetalert';

const AddMediaComponent = (props) => {
  const { from, setPreviewItem } = props;
  const [display, setDisplay] = useState(false);
  const [fileLibraryList, setFileLibraryList] = useState([]);
  const dispatch = useDispatch();

  const media = useSelector((state) => state.getMedia);
  const addMedia = useSelector((state) => state.addMedia);

  const mediaArr = media?.data?.entityList?.map((file) => {
    return {
      _id: file.Identifier,
      title: file.Name,
      size: file.Size * 1024 * 1024,
      fileName: '',
      type: file.Type,
      createdAt: '',
      thumbnailUrl:
        file.Type === 'document'
          ? 'https://www.pngkit.com/png/detail/862-8621313_png-file-svg-black-draft-document-icon.png'
          : file.Type === 'image'
          ? file.Path
          : 'https://www.pngkit.com/png/full/432-4322906_image-result-for-video-clipart-png-music-video.png',
      realPath: file.Path,
    };
  });

  useEffect(() => {
    dispatch(getMediaAction());
  }, [dispatch]);

  useEffect(() => {
    setFileLibraryList(mediaArr);
  }, [media, addMedia]);

  async function uploadCallback(fileBase64, meta) {
    const file = dataURItoBlob(fileBase64);
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', file.type);
    formData.append('fileName', meta.fileName);
    dispatch(addMediaAction({ data: formData }));

    return true;
  }

  function selectCallback(item) {
    setDisplay(false);
    window.open(item?.realPath, '_blank');
  }

  function selectCallbackForCard(item) {
    setDisplay(false);
    setPreviewItem(item);
  }

  async function deleteCallback(item) {
    swal({
      title: 'Are you sure?',
      text: 'You want to delete this item!',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'cancel_button',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'confirm_button',
          closeModal: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteMediaAction(item._id));
      } else {
        return true;
      }
    });
  }

  return (
    <React.Fragment>
      <button
        onClick={() => setDisplay(true)}
        type='button'
        className={`btn btn-primary font-weight-bold px-9 py-4`}
        style={{
          maxHeight: 50,
          background: '#ff6600',
          border: 'none',
          marginBottom: '20px',
        }}
      >
        Open media library
      </button>

      <ReactMediaLibrary
        show={display}
        onHide={() => setDisplay(false)}
        fileUploadCallback={uploadCallback}
        fileLibraryList={fileLibraryList}
        fileSelectCallback={
          from === 'card' ? selectCallbackForCard : selectCallback
        }
        fileDeleteCallback={deleteCallback}
      />
    </React.Fragment>
  );
};

export default AddMediaComponent;
