import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import { setLocalState } from "../../helpers/localStorage";
import {
  START_SUBSCRIBTION_INFO_REQUEST,
  SUBSCRIBTION_INFO_REQUEST_SUCCESS,
  SUBSCRIBTION_INFO_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* getSubscribtionInfoWatcher() {
  yield takeLatest(START_SUBSCRIBTION_INFO_REQUEST, getSubscribtionInfoWorker);
}

function getSubscribtionInfo() {
  return axios.get("api/dashboard");
}

function* getSubscribtionInfoWorker() {
  try {
    const response = yield call(getSubscribtionInfo);
    const data = response.data;
    setLocalState("@user", data.entity);
    yield put({ type: SUBSCRIBTION_INFO_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: SUBSCRIBTION_INFO_REQUEST_FAILED, error });
  }
}
