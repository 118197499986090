import React from 'react';
import VideoRecorder from 'react-video-recorder';

const VideoRecorderComponent = ({ onSuccess }) => {
  return (
    <div>
      <VideoRecorder
        onRecordingComplete={(file) => {
          onSuccess(file, 'video');
        }}
      />
    </div>
  );
};

export default VideoRecorderComponent;
