import { takeLatest, put, retry } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  STRIPE_START_PAYMENT_STATUS_REQUEST,
  STRIPE_PAYMENT_STATUS_REQUEST_SUCCESS,
  STRIPE_PAYMENT_STATUS_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* stripePaymentStatusWatcher() {
  yield takeLatest(
    STRIPE_START_PAYMENT_STATUS_REQUEST,
    stripePaymentStatusWorker
  );
}

function stripePaymentStatus(payload) {
  return axios.post("api/stripe-status", payload);
}

function* stripePaymentStatusWorker(action) {
  try {
    const response = yield retry(3, 1000, stripePaymentStatus, action.payload);
    const data = response.data;

    yield put({ type: STRIPE_PAYMENT_STATUS_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: STRIPE_PAYMENT_STATUS_REQUEST_FAILED, error });
  }
}
