import {
  CONFIRM_FORGETTOKEN_REQUEST_FAILED,
  CONFIRM_FORGETTOKEN_REQUEST_SUCCESS,
  START_CONFIRM_FORGETTOKEN_REQUEST,
} from "../../actions/actionTypes";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

export function confirmForgetTokenReducer(state = initialState, action) {
  switch (action.type) {
    case START_CONFIRM_FORGETTOKEN_REQUEST:
      return { ...state, fetching: true, error: null };
    case CONFIRM_FORGETTOKEN_REQUEST_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case CONFIRM_FORGETTOKEN_REQUEST_FAILED:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
}
