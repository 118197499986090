import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './custom-fileuploader.scss';

const CustomFileUploader = ({ type, accept, icon, onFileSelectSuccess }) => {
  const fileRef = useRef(null);

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    onFileSelectSuccess(file, type);
  };

  return (
    <div className='custom-file-uploader'>
      <input
        className='custom-file-uploader__input'
        type='file'
        name='document'
        accept={accept}
        onChange={handleUploadClick}
        ref={fileRef}
      />
      <button
        className='custom-file-uploader__button'
        onClick={(event) => {
          event.preventDefault();
          fileRef.current.click();
        }}
      >
        <i className='custom-file-uploader__icon'>
          <FontAwesomeIcon icon={icon} />
        </i>
      </button>
    </div>
  );
};

export default CustomFileUploader;
