import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import {
  START_DELETE_BENEFECIARY_REQUEST,
  DELETE_BENEFECIARY_REQUEST_SUCCESS,
  DELETE_BENEFECIARY_REQUEST_FAILED,
  START_BENEFECIARIES_REQUEST,
} from "../../actions/actionTypes";
import swal from "sweetalert";

export function* deleteBeneficiaryWatcher() {
  yield takeLatest(START_DELETE_BENEFECIARY_REQUEST, deleteBeneficiaryWorker);
}

function deleteBeneficiary(payload) {
  return axios.delete(`api/beneficiaries/delete`, {
    data: { Identifier: payload },
  });
}

function* deleteBeneficiaryWorker(action) {
  try {
    const response = yield call(deleteBeneficiary, action.payload);
    const data = response.data;

    yield put({ type: DELETE_BENEFECIARY_REQUEST_SUCCESS, data });

    if (data) {
      swal("", "Deleted successfully!", "success");
      yield put({ type: START_BENEFECIARIES_REQUEST });
    }
  } catch (error) {
    yield put({ type: DELETE_BENEFECIARY_REQUEST_FAILED, error });
  }
}
