import React from 'react';
import './dashboard-card-list.scss';

const DashboardCard = ({ src, title, dataInfo, footerInfo }) => (
  <div className='dashboard-card'>
    <div className='dashboard-card__body'>
      <div className='d-flex'>
        <img src={src} alt='' />
      </div>
      <div className='dashboard-card__body__info'>
        <h3 className='dashboard-card__body__info__indicator'>{title}</h3>
        <h3 className='dashboard-card__body__info__status'>{dataInfo}</h3>
      </div>
    </div>
    <div className='dashboard-card__footer'>{footerInfo}</div>
  </div>
);

const DashBoardCardList = ({ subscribtionInfo }) => {
  const { IsSubscriped, isFree } = subscribtionInfo?.data?.entity || {};
  return (
    <div className='dashboard-card-list row'>
      <div className='col-lg-3 col-sm-6'>
        <DashboardCard
          title='Member Since'
          src={'media/png/Member-Since.png'}
          dataInfo={subscribtionInfo?.data?.entity?.MemberSinceDays}
          footerInfo={subscribtionInfo?.data?.entity?.MemberSince}
        />
      </div>
      <div className='col-lg-3 col-sm-6'>
        <DashboardCard
          title='Media Library'
          src={'media/png/Media-Library.png'}
          dataInfo={
            (IsSubscriped === 1 || isFree === 1)
              ? subscribtionInfo?.data?.entity?.Subscription?.TotalFiles
              : 0
          }
          footerInfo='Since Account Creation'
        />
      </div>

      <div className='col-lg-3 col-sm-6'>
        <DashboardCard
          title='Scheduled Wyshes'
          src={'media/png/Scheduled-Wyshes.png'}
          dataInfo={
            (IsSubscriped === 1 || isFree === 1)
              ? subscribtionInfo?.data?.entity?.Subscription?.TotalSentEcards
              : 0
          }
          footerInfo='Since Account Creation'
        />
      </div>

      <div className='col-lg-3 col-sm-6'>
        <DashboardCard
          title='Total Beneficiaries'
          src={'media/png/Total-Beneficiaries.png'}
          dataInfo={subscribtionInfo?.data?.entity?.TotalBeneficiaries}
          footerInfo={`${subscribtionInfo?.data?.entity?.TotalNextOfKins} assigned as NoK`}
        />
      </div>
    </div>
  );
};

export default DashBoardCardList;
