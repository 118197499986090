import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getCountryCitiesAction } from '../../../redux/actions/getCountryCities';
import { getCountriesAction } from '../../../redux/actions/getCountries';
import { getRelationsAction } from '../../../redux/actions/Beneficiaries/getRelations';
import { addBeneficiaryAction } from '../../../redux/actions/Beneficiaries/addBeneficiary';
import toast from 'react-hot-toast';

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  address: '',
  cityName: '',
  postalCode: '',
  isNextOfKin: false,
};

function BeneficiariesForm(props) {
  const { intl } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [relation, setRelation] = useState('');

  useEffect(() => {
    dispatch(getCountriesAction());
    dispatch(getRelationsAction());
  }, []);

  const BeneficiariesFormSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    lastName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    phone: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    cityName: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    // cityName: Yup.string().required(
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //   })
    // ),
    // postalCode: Yup.string().required(
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //   })
    // ),
  });

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    const id = e.target.value;

    if (id) {
      dispatch(getCountryCitiesAction(parseInt(id)));
    }
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  const handleRelationChange = (e) => {
    setRelation(e.target.value);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const countries = useSelector((state) => state.countries);
  const cities = useSelector((state) => state.cities);
  const relations = useSelector((state) => state.relations);

  const formik = useFormik({
    initialValues,
    validationSchema: BeneficiariesFormSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!country) {
        toast.error('Please choose country');
        return '';
      }

      if (!region) {
        toast.error('Please choose region');
        return '';
      }

      if (!relation) {
        toast.error('Please choose relation');
        return '';
      }

      setSubmitting(true);
      enableLoading();
      const formData = new FormData();

      formData.append('FirstName', values.firstName);
      formData.append('LastName', values.lastName);
      formData.append('Email', values.email);
      formData.append('Phone', values.phone);
      formData.append('Address', values.address);
      formData.append('CountryID', parseInt(country));
      formData.append('CityID', parseInt(region));
      formData.append('CityName', values.cityName);
      formData.append('PostalCode', values.postalCode);
      formData.append('RelationID', parseInt(relation));
      formData.append('IsNextOfKin', values.IsNextOfKin === true ? 1 : 0);

      dispatch(addBeneficiaryAction(formData));

      setTimeout(() => {
        disableLoading();
        setSubmitting(false);
      }, 1500);
    },
  });

  return (
    <div style={{ background: '#fff', padding: '1.5rem' }}>
      {/* begin::Head */}
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>Beneficiary</h3>
        <p className='text-muted font-weight-bold'>Add beneficiary</p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        {/* {login?.error ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">
              {login.error.errors}
            </div>
          </div>
        ) : null} */}

        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>First name</label>
              <input
                placeholder='First name'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'firstName'
                )}`}
                name='firstName'
                {...formik.getFieldProps('firstName')}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.firstName}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Last name</label>
              <input
                placeholder='Last name'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'lastName'
                )}`}
                name='lastName'
                {...formik.getFieldProps('lastName')}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.lastName}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Phone</label>
              <input
                placeholder='Phone'
                type='phone'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'phone'
                )}`}
                name='phone'
                {...formik.getFieldProps('phone')}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Email</label>
              <input
                placeholder='Email'
                type='email'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'email'
                )}`}
                name='email'
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Address</label>
              <input
                placeholder='Address'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'address'
                )}`}
                name='address'
                {...formik.getFieldProps('address')}
              />
              {formik.touched.address && formik.errors.address ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Country</label>
              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'country'
                )}`}
                name='country'
                onChange={(e) => handleCountryChange(e)}
              >
                <option value=''>Select Country</option>
                {countries?.data?.entityList?.map((country) => {
                  return (
                    <option key={country.Identifier} value={country.Identifier}>
                      {country.Name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.country && formik.errors.country ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.country}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Region</label>
              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'region'
                )}`}
                name='region'
                onChange={(e) => handleRegionChange(e)}
              >
                <option value=''>Select Region</option>
                {cities?.data?.entityList?.map((region) => {
                  return (
                    <option key={region.Identifier} value={region.Identifier}>
                      {region.Name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.city && formik.errors.region ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.region}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>City</label>
              <input
                placeholder='City'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'cityName'
                )}`}
                name='cityName'
                {...formik.getFieldProps('cityName')}
              />
              {formik.touched.cityName && formik.errors.cityName ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.cityName}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Postal Code</label>
              <input
                placeholder='Postal code'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'postalCode'
                )}`}
                name='postalCode'
                {...formik.getFieldProps('postalCode')}
              />
              {formik.touched.postalCode && formik.errors.postalCode ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors.postalCode}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Relation</label>
              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'relation'
                )}`}
                name='relation'
                onChange={(e) => handleRelationChange(e)}
              >
                <option value=''>Select Relation</option>
                {relations?.data?.entityList?.map((relation) => {
                  return (
                    <option
                      key={relation.Identifier}
                      value={relation.Identifier}
                    >
                      {relation.Title}
                    </option>
                  );
                })}
              </select>
              {formik.touched.relation && formik.errors.relation ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.relation}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='form-group'>
          <label className='checkbox'>
            <input
              type='checkbox'
              name='IsNextOfKin'
              className='mx-3 form-control form-control-solid'
              {...formik.getFieldProps('IsNextOfKin')}
            />
            Make next of kin{' '}
            <span style={{ border: '1px solid #3699FF', marginLeft: 10 }} />
          </label>
          {formik.touched.IsNextOfKin && formik.errors.IsNextOfKin ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.IsNextOfKin}</div>
            </div>
          ) : null}
          <span>
            The appointment of your Next of Kin is an important task as this
            person will have various responsibilities to ensure the ewyshes you
            create are delivered to your loved ones. Please inform that of this
            task and ensure they complete their account. It is your
            responsibility to ensure your nominated Next of Kin is up to date.
            Consider having more than one person as your Next of Kin, fully
            ensuring that ewysh is informed of changes and when to ensure your
            ewyshes are delivered.
          </span>
        </div>
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Save</span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(BeneficiariesForm);
