import React, { useEffect } from 'react';
import { getLocalState } from '../../../helpers/localStorage';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMediaAction } from '../../../redux/actions/Media/getMedia';
import MediaGallery from './MediaGallery/MediaGallery';
import './media-library.scss';

const MediaPage = () => {
  const dispatch = useDispatch();
  const media = useSelector((state) => state.getMedia);

  const data = getLocalState('@user');

  useEffect(() => {
    dispatch(getMediaAction());
  }, [dispatch]);

  return (
    <div className='row'>
      <div className='col-lg-6 col-sm-12'>
        {(data.IsSubscriped === 1 || data.isFree === 1) ? (
          <MediaGallery media={media} />
        ) : (
          <>
            <p>Please subscribe to unlock all features</p>
            <Link
              to='/plans'
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              style={{
                background: '#ff6600',
                border: '1px solid #ff6600',
                marginBottom: '20px',
              }}
            >
              <span>Subscribe</span>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default MediaPage;
