import toast from "react-hot-toast";
import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import { setLocalState } from "../../../helpers/localStorage";
import {
  START_LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAILED,
} from "../../actions/actionTypes";

export function* loginWatcher() {
  yield takeLatest(START_LOGIN_REQUEST, loginWorker);
}

function login(data) {
  return axios.post("api/login", data);
}

function* loginWorker(action) {
  try {
    const response = yield call(login, action.payload);
    const data = response?.data;
    console.log('data', data);

    if (data.entity) {
      setLocalState("@user", data?.entity);
      setLocalState("@token", data?.entity?.access_token);

      yield put({ type: LOGIN_REQUEST_SUCCESS, data });
    } else {
      data.errors && toast.error(data.errors);
    }
  } catch (error) {
    yield put({ type: LOGIN_REQUEST_FAILED, error: error?.response?.data });
  }
}
