import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../../API/axiosClient';
import {
  START_ADD_BENEFECIARY_REQUEST,
  ADD_BENEFECIARY_REQUEST_SUCCESS,
  ADD_BENEFECIARY_REQUEST_FAILED,
} from '../../actions/actionTypes';
import swal from 'sweetalert';
import toast from 'react-hot-toast';

export function* addBeneficiaryWatcher() {
  yield takeLatest(START_ADD_BENEFECIARY_REQUEST, addBeneficiaryWorker);
}

function addBeneficiary(data) {
  return axios.post('api/beneficiaries', data);
}

function* addBeneficiaryWorker(action) {
  try {
    const response = yield call(addBeneficiary, action.payload);
    const data = response.data;

    yield put({ type: ADD_BENEFECIARY_REQUEST_SUCCESS, data });

    if (data?.code === 201) {
      swal('Beneficiary!', 'Added successfully!', 'success').then(() => {
        window.location.pathname = '/beneficiaries';
      });
    } else {
      var errorMsg = '';
      Object.values(data?.errors).forEach(([error]) => (errorMsg += error));
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: ADD_BENEFECIARY_REQUEST_FAILED, error });
    swal('Beneficiary!', `${error?.response?.data?.errorMsg}`, 'warning');
  }
}
