import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscribtionInfoAction } from '../../../redux/actions/getSubscribtionInfo';
import { getnNOKUsersAction } from '../../../redux/actions/getNOKUsers';
import swal from 'sweetalert';
import { getLocalState, setLocalState } from '../../../helpers/localStorage';
import AppleLogo from '../../../_metronic/_assets/images/app-store.png';
import GoogleLogo from '../../../_metronic/_assets/images/google-play.png';
import RecommendationForm from './RecommendationForm/RecommendationForm';
import DashBoardCardList from './DashBoardCardList/DashBoardCardList';
import UserPlanCard from './UserPlanCard/UserPlanCard';
import { NOKUsersTable } from './NoKTable/NOKtable';
import { paymentStatusAction } from '../../../redux/actions/payamentStatus';

export default function DashboardPage(props) {
  const dispatch = useDispatch();
  const subscribtionInfo = useSelector((state) => state.subscribtionInfo);
  const nokUsers = useSelector((state) => state.NOKUsers);

  const { IsNextOfKin } = subscribtionInfo?.data?.entity || {};

  useEffect(() => {
    dispatch(getSubscribtionInfoAction());
    dispatch(getnNOKUsersAction());
  }, [dispatch]);

  useEffect(() => {
    const IsSubscriped = getLocalState('@IsSubscriped');
    if (IsSubscriped?.viewed === true) return;

    getSubscribtionInfo();
  }, [subscribtionInfo]);

  function getSubscribtionInfo() {
    if (
      subscribtionInfo?.data?.entity?.IsSubscriped === 0 && subscribtionInfo?.data?.entity?.isFree === 0
    ) {
      swal({
        title: 'You are not subscribed to any package yet!!',
        text: 'Subscribe now and unlock all features',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancel',
            value: null,
            visible: true,
            className: 'cancel_button',
            closeModal: true,
          },
          confirm: {
            text: 'Take me to plans page',
            value: true,
            visible: true,
            className: 'confirm_button',
            closeModal: true,
          },
        },
      }).then((ok) => {
        setLocalState('@IsSubscriped', { viewed: true });
        if (ok) {
          window.location.pathname = '/plans';
        } else {
          window.location.reload();
        }
      });
    }
  }

  let URLParams = props?.location?.search;

  URLParams = URLParams?.split('&');

  const payment_id = URLParams[0]?.split('=');
  const resourcePath = URLParams[1]?.split('=');

  if (payment_id && resourcePath) {
    const formData = new FormData();

    formData.append('payment_id', payment_id[1]);
    formData.append('resourcePath', resourcePath[1]);

    dispatch(paymentStatusAction(formData));
  }

  return (
    <>
      {IsNextOfKin === 1 ? (
        <div role='alert' className='fade alert alert-primary show'>
          <h5>
            You are a Next of kin account , you are responsible for confirming
            deceased for you users listed in table below
          </h5>
        </div>
      ) : null}

      <DashBoardCardList subscribtionInfo={subscribtionInfo} />

      <div
        className='row dashboard__chart-list'
        style={{ height: IsNextOfKin ? '350px' : '450px' }}>
        <div
          className={`${IsNextOfKin ? 'col-4' : 'col-6'}`}>
          <UserPlanCard subscribtionInfo={subscribtionInfo} />
        </div>
        <div
          className={`${IsNextOfKin ? 'col-4' : 'col-6'}`}>
          {' '}
          <RecommendationForm />
        </div>
        {IsNextOfKin === 1 && (
          <div
            className={`${IsNextOfKin ? 'col-4' : 'col-6'}`}>
            {' '}
            {<NOKUsersTable nokUsers={nokUsers.data} />}
          </div>
        )}
      </div>

      <div className='dashboard__app-list'>
        <a href='https://apps.apple.com/us/app/ewysh/id1594063345' rel="noreferrer noreferrer" target="_blank" >
          <img className='pull-left' src={AppleLogo} alt='' />
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.afterlife' rel="noreferrer noreferrer" target="_blank" >
          <img className='pull-left' src={GoogleLogo} alt='' />
        </a>
      </div>
    </>
  );
}
