/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from '../app/Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import { Toaster } from 'react-hot-toast';

export default function App({ store, basename }) {
  return (
    <Provider store={store}>
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          <MaterialThemeProvider>
            <I18nProvider>
              <Routes />
              <Toaster
                position='top-center'
                containerStyle={{
                  marginTop: '5rem',
                  fontSize: '1.5rem',
                }}
              />
            </I18nProvider>
          </MaterialThemeProvider>
        </BrowserRouter>
      </React.Suspense>
    </Provider>
  );
}
