import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../API/axiosClient';
import {
  START_SEND_SUPPORT_EMAIL,
  SEND_SUPPORT_EMAIL_SUCCESS,
  SEND_SUPPORT_EMAIL_FAILED,
} from '../actions/actionTypes';
import swal from 'sweetalert';

export function* sendSupportWatcher() {
  yield takeLatest(START_SEND_SUPPORT_EMAIL, sendSupportWorker);
}

function sendSupport(payload) {
  return axios.post('api/save/help', payload);
}

function* sendSupportWorker(action) {
  try {
    const response = yield call(sendSupport, action.payload);
    const data = response.data;

    if (!data.errorMsg) {
      swal('Support!', data.message, 'success');
    } else {
      swal('Support!', `Failed to send the email!, ${data.errorMsg}`, 'error');
    }

    yield put({ type: SEND_SUPPORT_EMAIL_SUCCESS, data });
  } catch (error) {
    yield put({ type: SEND_SUPPORT_EMAIL_FAILED, error });
  }
}
