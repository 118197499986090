import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Link, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { changePasswordAction } from '../../../redux/actions/changePassword';
import './change-password.scss';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmpassword: '',
};

function ChangePassword(props) {
  const { intl } = props;
  const dispatch = useDispatch();
  const [isRequested] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, 'Minimum 8 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    newPassword: Yup.string()
      .min(3, 'Minimum 8 symbols')
      .max(50, 'Maximum 50 symbols')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .when('newPassword', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('newPassword')],
          'Passwords not matched'
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const passwordLengthValidation = () => {
    let newPassword = formik.values.newPassword;

    const upperCaseConstrain = () => {
      return /[A-Z]/.test(newPassword);
    };

    const lowerCaseConstrain = () => {
      return /[a-z]/.test(newPassword);
    };

    const digitConstrain = () => {
      return /\d/.test(newPassword);
    };

    const lengthConstrain = () => {
      return newPassword.length >= 8;
    };

    if (formik.touched.newPassword && formik.errors.newPassword) {
      return (
        <div className='password-requirements'>
          {upperCaseConstrain() ? (
            <div className='password-requirements__text password-requirements__text--success'>
              <img
                alt=''
                src={toAbsoluteUrl('/media/png/green-checkmark.png')}
              />
              At least one uppercase letter
            </div>
          ) : (
            <div className='password-requirements__text password-requirements__text--error'>
              <img
                alt=''
                src={toAbsoluteUrl('/media/png/red-cross-icon.png')}
              />
              At least one uppercase letter
            </div>
          )}
          {/* ********************** */}
          {lowerCaseConstrain() ? (
            <div className='password-requirements__text password-requirements__text--success'>
              <img
                alt=''
                src={toAbsoluteUrl('/media/png/green-checkmark.png')}
              />
              At least one lowercase letter
            </div>
          ) : (
            <div className='password-requirements__text password-requirements__text--error'>
              <img
                alt=''
                src={toAbsoluteUrl('/media/png/red-cross-icon.png')}
              />
              At least one lowercase letter
            </div>
          )}
          {/* ********************** */}
          {digitConstrain() ? (
            <div className='password-requirements__text password-requirements__text--success'>
              <img
                alt=''
                src={toAbsoluteUrl('/media/png/green-checkmark.png')}
              />
              At least one digit
            </div>
          ) : (
            <div className='password-requirements__text password-requirements__text--error'>
              <img
                alt=''
                src={toAbsoluteUrl('/media/png/red-cross-icon.png')}
              />
              At least one digit
            </div>
          )}
          {/* ********************** */}
          {lengthConstrain() ? (
            <div className='password-requirements__text password-requirements__text--success'>
              <img
                alt=''
                src={toAbsoluteUrl('/media/png/green-checkmark.png')}
              />
              At least 8 character in length
            </div>
          ) : (
            <div className='password-requirements__text password-requirements__text--error'>
              <img
                alt=''
                src={toAbsoluteUrl('/media/png/red-cross-icon.png')}
              />
              At least 8 character in length
            </div>
          )}
        </div>
      );
    }

    if (formik.touched.newPassword && !formik.errors.newPassword) {
      return (
        <div className='password-requirements'>
          <div className='password-requirements__text password-requirements__text--success'>
            <img alt='' src={toAbsoluteUrl('/media/png/green-checkmark.png')} />
            At least one lowercase letter
          </div>
          <div className='password-requirements__text password-requirements__text--success'>
            <img alt='' src={toAbsoluteUrl('/media/png/green-checkmark.png')} />
            At least one uppercase letter
          </div>
          <div className='password-requirements__text password-requirements__text--success'>
            <img alt='' src={toAbsoluteUrl('/media/png/green-checkmark.png')} />
            At least one digit
          </div>
          <div className='password-requirements__text password-requirements__text--success'>
            <img alt='' src={toAbsoluteUrl('/media/png/green-checkmark.png')} />
            At least 8 character in length
          </div>
        </div>
      );
    }

    return (
      <div className='password-requirements'>
        <div className='password-requirements__text'>
          At least one lowercase letter
        </div>
        <div className='password-requirements__text'>
          At least one uppercase letter
        </div>
        <div className='password-requirements__text'>At least one digit</div>
        <div className='password-requirements__text'>
          At least 8 character in length
        </div>
      </div>
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);

      const formData = new FormData();

      formData.append('OldPassword', values.oldPassword);
      formData.append('Password', values.newPassword);

      dispatch(changePasswordAction(formData));

      setTimeout(() => {
        setSubmitting(false);
      }, 1500);
    },
  });

  return (
    <>
      {isRequested && <Redirect to='/auth' />}
      {!isRequested && (
        <div className='change-password'>
          <form
            onSubmit={formik.handleSubmit}
            className='change-password-form form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
          >
            {formik.status && (
              <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
                <div className='alert-text font-weight-bold'>
                  {formik.status}
                </div>
              </div>
            )}

            {/* begin: Password */}

            <div className='input-group fv-plugins-icon-container my-5'>
              <input
                placeholder='Old password'
                type={showPassword === true ? 'text' : 'password'}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'password'
                )}`}
                name='oldPassword'
                {...formik.getFieldProps('oldPassword')}
              />
              <div className='input-group-prepend'>
                <span
                  className='input-group-text'
                  style={{ border: 'none' }}
                  onClick={() =>
                    setShowPassword(showPassword === true ? false : true)
                  }
                >
                  <SVG src={toAbsoluteUrl('/media/svg/Hidden.svg')} />
                </span>
              </div>
            </div>
            {formik.touched.oldPassword && formik.errors.oldPassword ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.oldPassword}</div>
              </div>
            ) : null}
            {/* end: Password */}

            <div className='input-group fv-plugins-icon-container my-5'>
              <input
                placeholder='New Password'
                type={showPassword === true ? 'text' : 'password'}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'newPassword'
                )}`}
                name='newPassword'
                {...formik.getFieldProps('newPassword')}
              />
              <div className='input-group-prepend'>
                <span
                  className='input-group-text'
                  style={{ border: 'none' }}
                  onClick={() =>
                    setShowPassword(showPassword === true ? false : true)
                  }
                >
                  <SVG src={toAbsoluteUrl('/media/svg/Hidden.svg')} />
                </span>
              </div>
            </div>
            {/* {formik.touched.newPassword && formik.errors.newPassword ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.newPassword}</div>
              </div>
            ) : null} */}
            <div className='input-group fv-plugins-icon-container my-5'>
              <input
                placeholder='Confirm Password'
                type={showPassword === true ? 'text' : 'password'}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'confirmPassword'
                )}`}
                name='confirmPassword'
                {...formik.getFieldProps('confirmPassword')}
              />
              <div className='input-group-prepend'>
                <span
                  className='input-group-text'
                  style={{ border: 'none' }}
                  onClick={() =>
                    setShowPassword(showPassword === true ? false : true)
                  }
                >
                  <SVG src={toAbsoluteUrl('/media/svg/Hidden.svg')} />
                </span>
              </div>
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  {formik.errors.confirmPassword}
                </div>
              </div>
            ) : null}

            <div className='change-password-form__buttons-wrapper'>
              <button
                id='kt_login_forgot_submit'
                type='submit'
                className='change-password-form__button change-password-form__button--reset'
                disabled={formik.isSubmitting}
              >
                Change
              </button>
              <Link to='/auth'>
                <button
                  type='button'
                  id='kt_login_forgot_cancel'
                  className='change-password-form__button change-password-form__button--cancel'
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>

          <div className='change-password-validation'>
            <h3 className='change-password-validation__title'>
              Password Requirements
            </h3>
            <p>{passwordLengthValidation()}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(ChangePassword);
