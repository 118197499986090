import {
  START_SUBSCRIBTION_INFO_REQUEST,
  SUBSCRIBTION_INFO_REQUEST_SUCCESS,
  SUBSCRIBTION_INFO_REQUEST_FAILED,
} from "../actions/actionTypes";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

export function getSubscribtionInfoReducer(state = initialState, action) {
  switch (action.type) {
    case START_SUBSCRIBTION_INFO_REQUEST:
      return { ...state, fetching: true, error: null };
    case SUBSCRIBTION_INFO_REQUEST_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case SUBSCRIBTION_INFO_REQUEST_FAILED:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
}
