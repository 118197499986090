import toast from "react-hot-toast";
import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import { setLocalState } from "../../../helpers/localStorage";
import {
  REGISTER_REQUEST_SUCCESS,
  START_REGISTER_REQUEST,
  REGISTER_REQUEST_FAILED,
} from "../../actions/actionTypes";

export function* registerWatcher() {
  yield takeLatest(START_REGISTER_REQUEST, registerWorker);
}

function register(data) {
  return axios.post("api/register", data);
}

function* registerWorker(action) {
  try {
    const response = yield call(register, action.payload);
    const data = response.data;
    if (data) {
      setLocalState("@register", data);
    }

    yield put({ type: REGISTER_REQUEST_SUCCESS, data });

    if (data.errors) {
      for (let e in data.errors) {
        toast.error(data.errors[e] ?? data.errors[e][0]);
      }
    }
  } catch (error) {
    yield put({ type: REGISTER_REQUEST_FAILED, error: error?.response?.data });
  }
}
