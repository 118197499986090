import { useFormik } from 'formik';
import React from 'react';
import { useDispatch} from 'react-redux';
import { Link } from 'react-router-dom';
import { getLocalState } from '../../../helpers/localStorage';
import { sendSupportAction } from '../../../redux/actions/sendSupport';
import { supportSchema } from './schema';

import './supported-page.scss';

const initialValues = {
  subject: '',
  questions: '',
  category: ''
};

const SupportPage = () => {
  const dispatch = useDispatch();

  const userData = getLocalState('@user');

  const onSubmit = (values) => {
    const data = {
      ...values,
      user_id: userData.Identifier
    }
    dispatch(sendSupportAction(data));
  };

  const { handleSubmit, getFieldProps, touched, errors } = useFormik({
    initialValues,
    validationSchema: supportSchema,
    onSubmit: onSubmit,
  });

  const getInputClasses = (fieldName) => {
    if (touched[fieldName] && errors[fieldName]) {
      return 'is-invalid';
    }
    if (touched[fieldName] && !errors[fieldName]) {
      return 'is-valid';
    }
    return '';
  };

  return (
    <section className="supported-page">
      <div className="row">
        <div className="col">
          <h1>Submit Support Ticket</h1>
          <h5>Help and Knowledge Base</h5>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="my-2">Before you submit your support ticket, please check this topics below. You might find an answer to your questions/case instantly.</p>
          <h6>
            <Link to="/help" className="my-3 text-success">FAQ</Link>
          </h6>
          <h5>Reach Support Desk</h5>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <form onSubmit={handleSubmit} >
            <div className='fv-plugins-icon-container'>
              <label htmlFor="title">Subject</label>
              <input
                placeholder='Subject'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('subject')}`}
                name='subject'
                {...getFieldProps('subject')}
              />
              {touched.subject && errors.subject ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{errors.subject}</div>
                </div>
              ) : null}
            </div>
            <div className='fv-plugins-icon-container'>
              <label htmlFor="options">Category</label>
              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('category')}`}
                name="category"
                {...getFieldProps('category')}
              >
                <option value="">Select</option>
                <option value="Plans">Plans</option>
                <option value="Payment">Payment</option>
                <option value="Beneficiaries">Beneficiaries</option>
                <option value="Schedule ewysh">Schedule ewysh</option>
              </select>
              {touched.category && errors.category ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{errors.category}</div>
                </div>
              ) : null}
            </div>
            <div className='fv-plugins-icon-container'>
              <label>Questions</label>
              <textarea
                placeholder='Questions'
                rows="5"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('questions')}`}
                name='questions'
                {...getFieldProps('questions')}
              />
              {touched.questions && errors.questions ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{errors.questions}</div>
                </div>
              ) : null}
            </div>
            <input
              className="mt-3"
              type="checkbox"
              name="urgent"
              {...getFieldProps('urgent')}
            />
            <label className="mt-2 ml-2">Check this to mark as urgent</label>

            <hr />

            <button type="submit" className="submit-btn btn btn-success">Send</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SupportPage;
