import React, { useState } from "react";
import { deleteMediaAction } from "../../../../redux/actions/Media/deleteMedia";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import "./media-card.scss";

const MediaCard = ({ item }) => {
  const dispatch = useDispatch();
  const [openMediaModal, setOpenMediaModal] = useState(false);

  const closeMediaModal = () => setOpenMediaModal(false);

  const viewMedia = () => {
    if (item.Type === "document") window.open(item?.Path, "_blank");
    else setOpenMediaModal(true);
  };

  async function deleteCallback(id) {
    swal({
      title: "Are you sure?",
      text: "You want to delete this media!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "cancel_button",
          closeModal: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          className: "confirm_button",
          closeModal: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteMediaAction(id));
      } else {
        return true;
      }
    });
  }

  return (
    <>
      <div className="media-card">
        <div className="media-card__info">
          <div className="media-card__info__media">
            {item.Type != "video" ? (
              <img src={item.Thumbnail} alt="" />
            ) : (
              <video className="media-card__info__media2">
                <source src={item?.Path} type="video/mp4" />
                <source src={item?.Path} type="video/webm" />
                <source src={item?.Path} type="video/ogg" />
              </video>
            )}
          </div>
          <div className="media-card__info__data">
            <h5 className="media-card__info__data__title">{item.Name}</h5>
            <p className="media-card__info__data__text">
              Created on:{" "}
              {new Date(item.UpdatedAt).toLocaleDateString("en-US", {
                month: "short",
                year: "numeric",
                day: "numeric",
              })}
            </p>
            <p className="media-card__info__data__text">
              Size: {item.Size}
              {item.Unit}
            </p>
            <p className="media-card__info__data__text">
              <span>Type: </span> {item.Type}
            </p>
          </div>
        </div>
        <div className="media-card__actions">
          <button
            className="media-card__actions__view-button"
            onClick={viewMedia}
          >
            View
          </button>
          <button
            className="media-card__actions__delete-button"
            onClick={() => deleteCallback(item.Identifier)}
          >
            Delete
          </button>
        </div>
      </div>
      <Modal
        show={openMediaModal}
        onHide={closeMediaModal}
        wrapClassName="media-card__custom-modal"
      >
        <div className="media-card__previewer">
          <button
            className="media-card__close-btn"
            onClick={closeMediaModal}
            type="button"
          >
            X
          </button>

          {item?.Type === "image" && <img src={item?.Path} alt="" />}
          {item?.Type === "video" && (
            <video id="video" controls>
              <source src={item?.Path} type="video/mp4" />
              <source src={item?.Path} type="video/webm" />
              <source src={item?.Path} type="video/ogg" />
            </video>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MediaCard;
