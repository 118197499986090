/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { confirmDeacesedAction } from '../../../../redux/actions/confirmDeacesed';
import swal from 'sweetalert';

const initialValues = {
  deathDate: '',
  deathPlace: '',
  NOK: '',
};

export function NOKUsersTable({ className, nokUsers }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [secondConfirmShow, setSecondConfirmShow] = useState(false);
  const [NOK, setNOK] = useState('');
  const [targetUser, setTargetUser] = useState(null);
  const [dataToConfirmWith, setDataToConfirmWith] = useState(null);

  const secondConfirm = () => {
    dispatch(confirmDeacesedAction(dataToConfirmWith));
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setTargetUser(id);
    setShow(true);
  };

  const handleNOKChange = (e) => {
    setNOK(e.target.value);
  };

  const SubHeaderSchema = Yup.object().shape({
    deathDate: Yup.string().required('required'),
    deathPlace: Yup.string().required('required'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: SubHeaderSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const formData = new FormData();

      formData.append('DeathDate', values.deathDate);
      formData.append('DeathPlace', values.deathPlace);
      formData.append('user_id', targetUser);
      setDataToConfirmWith(formData);
      formik.resetForm();
    },
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  if (dataToConfirmWith && !secondConfirmShow) {
    setSecondConfirmShow(true);
    swal({
      title: 'Are you sure?',
      text: 'You want to confirm deacesed?',
      icon: 'success',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'cancel_button',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'confirm_button',
          closeModal: true,
        },
      },
    }).then((ok) => {
      if (ok) {
        setShow(false);
        secondConfirm();
      } else {
        return true;
      }
    });
  }

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label font-weight-bolder text-dark'>
            Users to confirm deceased for
          </span>
          <span className='text-muted mt-3 font-weight-bold font-size-sm'>
            {' '}
            {nokUsers?.entityList?.length} user
          </span>
        </h3>
      </div>
      {/* Body */}
      <div className='card-body pt-0 pb-3'>
        <div className='tab-content'>
          <div className='table-responsive'>
            <table className='table table-head-custom table-head-bg table-borderless table-vertical-center'>
              <thead>
                <tr className='text-left text-uppercase'>
                  <th style={{ minWidth: '100px' }}>Name</th>
                  <th style={{ minWidth: '80px' , float:'right' }} > Actions</th>
                </tr>
              </thead>
              <tbody>
                {nokUsers?.entityList?.map((user) => (
                  <tr>
                    <td className='pl-0 py-8'>
                      <div className='d-flex align-items-center'>
                        <div>
                          <span className='text-dark-75 font-weight-bolder mb-1 font-size-lg'>
                            {user.Name}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td className='pr-0 text-right'>
                      {user.ConfirmDeceased === 0 ? (
                        <button
                          className='btn btn-primary font-weight-bolder font-size-sm'
                          onClick={() => handleShow(user.Identifier)}
                        >
                          Confirm deceased
                        </button>
                      ) : (
                        <span className='text-dark-75 font-weight-bolder mb-1 font-size-lg'>
                          Confirmed on {user.ConfirmDeceasedAt}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <form
            onSubmit={formik.handleSubmit}
            className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
          >
            {formik.status && (
              <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
                <div className='alert-text font-weight-bold'>
                  {formik.status}
                </div>
              </div>
            )}
            <div className='form-group fv-plugins-icon-container'>
              <input
                type='date'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'deathDate'
                )}`}
                name='deathDate'
                {...formik.getFieldProps('deathDate')}
              />
              {formik.touched.deathDate && formik.errors.deathDate ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.deathDate}</div>
                </div>
              ) : null}
            </div>
            <div className='form-group fv-plugins-icon-container'>
              <input
                type='text'
                placeholder='Death place'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'deathPlace'
                )}`}
                name='deathPlace'
                {...formik.getFieldProps('deathPlace')}
              />
              {formik.touched.deathPlace && formik.errors.deathPlace ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors.deathPlace}
                  </div>
                </div>
              ) : null}
            </div>

            <div className='form-group d-flex flex-wrap flex-center'>
              <button
                id='kt_login_forgot_submit'
                type='submit'
                className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'
                disabled={formik.isSubmitting}
              >
                Confirm
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
