import React from 'react';
import { useHistory } from 'react-router';

const SubscripedUserCard = ({ subscribtionInfo }) => {
  const history = useHistory();

  console.log(subscribtionInfo);

  return (
    <>
      <div className='user-plan-card__header'>
        <div className='user-plan-card__header__title'> Your Plan</div>
      </div>
      <div className='user-plan-card__info'>
        <div className='user-plan-card__info__package'>
          <div className='user-plan-card__info__package__name'>
            <img src='media/logo/circular-logo.png' alt='' />
            <h1>{subscribtionInfo?.data?.entity?.Subscription?.PackageName}</h1>
          </div>
          <div className='user-plan-card__info__package__expiry'>
            <p>
              {`Expires ${new Date(
                subscribtionInfo?.data?.entity?.Subscription?.NextRenewalDate
              ).toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
              })}`}
            </p>
            <p>
              {`Auto Renew in ${subscribtionInfo?.data?.entity?.Subscription?.NextRenewalDateInDays} Days`}
            </p>
          </div>
        </div>

        <div className='user-plan-card__info__package-detail'> {subscribtionInfo?.data?.entity?.Subscription?.PackagePrice}</div>
        <div className='user-plan-card__info__package-detail'>
          {subscribtionInfo?.data?.entity?.Subscription?.PackageUsedWyshes}
        </div>
      </div>
      <div className='user-plan-card__cta'>
        <button
          onClick={() => history.push('/plans')}
          className='user-plan-card__upgrade-button'
        >
          Upgrade
        </button>
      </div>
    </>
  );
};

export default SubscripedUserCard;
