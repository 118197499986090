import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { CircularProgress } from '@material-ui/core';
import CardPreview from '../WyshScheduling/CardPreview';
import { listCardsAction } from '../../../redux/actions/listCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import ScheduledWyshCard from './ScheduledWyshCard/ScheduledWyshCard';
import './scheduled-wyshes.scss';

function HistoryList(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(listCardsAction());
  }, [dispatch]);

  const listCards = useSelector((state) => state.listCards);
  const previewECard = useSelector((state) => state.previewECard);

  return (
    <div className='history-page'>
      <button
        className='history-page__add-wysh-button'
        onClick={() => history.push('/cards')}
      >
        Add Wysh
      </button>

      <div className='scheduled-card__list'>
        {listCards?.data?.entityList?.length ? (
          <>
            {listCards?.data?.entityList?.map((wysh, index) => (
              <ScheduledWyshCard wysh={wysh} key={index} />
            ))}
          </>
        ) : (
          <div className='no-history'>
            {listCards?.fetching === true ? (
              <CircularProgress color='primary' />
            ) : (
              <div>
                <i className='no-history-icon'>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </i>
                <h2 className='no-history-heading'>No Scheduled History</h2>
                <p className='no-history-text'>
                  Use the "Add Wysh" button to create your Wyshes.
                </p>
                <p className='no-history-text'>
                  Your Wyshes always appear here.
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      <CardPreview
        show={show}
        handleClose={handleClose}
        cardData={previewECard}
      />
    </div>
  );
}

export default injectIntl(HistoryList);
