/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, Switch, Redirect, useHistory } from 'react-router-dom';
import { ContentRoute } from '../../../../_metronic/layout';
import logo from '../../../../assets/images/ewysh-logo-white.png';
import bgAuth from '../../../../assets/images/login-img.png';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import EmailConfirmation from './EmailConfirmation';
import ConfirmForgetToken from './ConfirmForgetToken';
import ResetPassword from './ResetPassword';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';

export function AuthPage() {
  const history = useHistory();
  const [pathName, setPathName] = useState('');

  // useEffect(() => {
  //   return history.listen((location) => {
  //     setPathName(location.pathname);
  //   });
  // }, [history]);

  const getYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <>
      <div className='d-flex flex-column flex-root'>
        {/*begin::Login*/}
        <div
          className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white'
          id='kt_login'
        >
          {/*begin::Aside*/}
          <div
            className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10'
            style={{
              backgroundImage: `url(${bgAuth})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className='d-flex flex-row-fluid flex-column justify-content-between'>
              {/* start:: Aside header */}
              <Link to='/' className='flex-column-auto mt-5 pb-lg-0 pb-10'>
                <img alt='Logo' className='max-h-70px' src={logo} />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside footer for desktop */}
              <div className='d-none flex-column-auto d-lg-flex justify-content-between mt-10'>
                <div className='opacity-70 font-weight-bold	text-white'>
                  &copy; {getYear()} ewysh
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className='d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden'>
            <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
              <Switch>
                <ContentRoute path='/auth/login' component={Login} />
                <ContentRoute
                  path='/auth/registration'
                  component={Registration}
                />
                <ContentRoute
                  path='/auth/forgot-password'
                  component={ForgotPassword}
                />
                <ContentRoute
                  path='/auth/forget-password/:email/:code'
                  component={ConfirmForgetToken}
                />
                <ContentRoute
                  path='/auth/reset-password/:email'
                  component={ResetPassword}
                />
                <ContentRoute
                  path='/auth/activation/:email/:code'
                  component={EmailConfirmation}
                />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className='d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5'>
              <div className='text-dark-50 font-weight-bold order-2 order-sm-1 my-2'>
                &copy; {getYear()}
              </div>
              <div className='d-flex order-1 order-sm-2 my-2'>
                <Link to='/terms' className='text-dark-75 text-hover-primary'>
                  Privacy
                </Link>
                <Link
                  to='/terms'
                  className='text-dark-75 text-hover-primary ml-4'
                >
                  Legal
                </Link>
                <Link
                  to='/terms'
                  className='text-dark-75 text-hover-primary ml-4'
                >
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
