import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPackagesAction } from '../../../redux/actions/getPackages';
import { paymentAction } from '../../../redux/actions/payment';
import PaymentPage from './paymentPage';
import { PlanCard, ActivePlan } from './planCard';
import { getLocalState } from '../../../helpers/localStorage';

const PlansPage = (props) => {
  const dispatch = useDispatch();
  const [isYearly, setIsYearly] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(getPackagesAction());
  }, [dispatch]);

  const packages = useSelector((state) => state.packages);
  const payment = useSelector((state) => state.payment);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePackageYearlySelected = () => {
    setIsYearly(isYearly === false ? true : false);
  };
  const paymentFreq = isYearly === true ? 'yearly' : 'monthly';

  const handlePayment = (id) => {
    const paymentData = {
      package_id: id,
      payment_frequency: paymentFreq,
    };

    dispatch(paymentAction(paymentData));
    handleShow();
  };

  const user = getLocalState('@user');
  const activePackageId = user?.Subscription?.PackageID;

  const activePlan = {
    Name: user?.Subscription?.PackageName,
    PackagePrice: user?.Subscription?.PackagePrice,
    PackageUsedWyshes: user?.Subscription?.PackageUsedWyshes,
  };

  return (
    <>
      <div className='plans-wrapper'>
        {user?.Subscription?.PackageID && (
          <div className=' active-plan-wrapper'>
            <ActivePlan card={activePlan} isYearly={isYearly} />
          </div>
        )}

        <div className='plans__filters-area'>
          <button
            className={`plans-filter-button ${
              isYearly === true ? '' : 'plans-filter-button--active'
            }`}
            onClick={() => handlePackageYearlySelected()}
          >
            Monthly
          </button>
          <button
            className={`plans-filter-button ${
              isYearly === true ? 'plans-filter-button--active' : ''
            }`}
            onClick={() => handlePackageYearlySelected()}
          >
            Yearly
          </button>
        </div>

        <div className='row'>
          {packages?.data?.entityList?.map((card) => {
            const active = card.Identifier === activePackageId;
            return (
              <div className='col-lg-4 col-sm-6' key={card.Identifier}>
                <PlanCard
                  card={card}
                  active={active}
                  isYearly={isYearly}
                  handlePayment={handlePayment}
                />
              </div>
            );
          })}
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <PaymentPage handleClose={handleClose} paymentData={payment} />
      </Modal>
    </>
  );
};

export default PlansPage;
