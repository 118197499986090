import React from 'react';

export const PlanCard = ({ card, isYearly, handlePayment, active }) => {
  // if (!active) {
  return (
    <div className='plan-card'>
      <div className='plan-card__body'>
        <div className='plan-card__body__info'>
          <div className='plan-type'>
            <div className='plan-type-name'>{card.Name}</div>
            <div className='plan-type-wyshes'>{card.Cards} Wyshes</div>
          </div>
          <p className='plan-fees'>
            ${isYearly === true ? card.PriceYearly : card.PriceMonthly}
            <span>{isYearly === true ? ' /Yearly' : ' /Monthly'}</span>
          </p>
        </div>
        <div className='plan-card__body__cta'>
          <button
            className='plan-card__body__cta__btn'
            onClick={() => handlePayment(card.Identifier)}
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
  // }

  // return (
  //   <div className='plan-card plan-card--active'>
  //     <div className='plan-card__body'>
  //       <div className='plan-card__title'>Your Plan</div>
  //       <div className='plan-card__body__info'>
  //         <div className='plan-type'>
  //           <div className='plan-type-name'>{card.Name}</div>
  //           <div className='plan-type-wyshes'>{card.Cards} Wyshes</div>
  //         </div>
  //         <p className='plan-fees'>
  //           £{isYearly === true ? card.PriceYearly : card.PriceMonthly}
  //           <span>{isYearly === true ? ' /Yearly' : ' /Monthly'}</span>
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export const ActivePlan = ({ card, isYearly }) => (
  <div className='plan-card plan-card--active' style={{ width: '32%' }}>
    <div className='plan-card__body'>
      <div className='plan-card__title'>Your Plan</div>
      <div className='plan-card__body__info'>
        <div className='plan-type'>
          <div className='plan-type-name'>{card.Name}</div>
          <div className='plan-type-wyshes'>
            {card.PackageUsedWyshes.substr(
              0,
              card.PackageUsedWyshes.lastIndexOf('\\')
            )}
          </div>
        </div>
        <p className='plan-fees'>{card.PackagePrice}</p>
      </div>
    </div>
  </div>
);
