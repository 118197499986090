import {
  START_SEND_SUPPORT_EMAIL,
  SEND_SUPPORT_EMAIL_SUCCESS,
  SEND_SUPPORT_EMAIL_FAILED,
} from "../actions/actionTypes";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

export function sendSupportReducer(state = initialState, action) {
  switch (action.type) {
    case START_SEND_SUPPORT_EMAIL:
      return { ...state, fetching: true, error: null };
    case SEND_SUPPORT_EMAIL_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case SEND_SUPPORT_EMAIL_FAILED:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
}
