import React from 'react';
import { useLocation } from 'react-router';
import {
  faPhotoVideo,
  faUserFriends,
  faCalendarAlt,
  faImage,
  faTachometerAlt,
  faUserCircle,
  faCheckCircle,
  faDoorOpen,
  faQuestion,
  faHeadphones
} from '@fortawesome/free-solid-svg-icons';
import { AsideMenuItem } from '../aside-menu-item/AsideMenuItem';
import { checkIsActive } from '../../../../_helpers';
import './aside-menu-list.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  const logout = () => {
    localStorage.clear('@token');
    localStorage.clear('@user');
    localStorage.clear('@register');
    window.location.pathname = '/';
  };

  const items = [
    {
      title: 'Dashboard',
      icon: faTachometerAlt,
      link: '/dashboard',
    },
    {
      title: 'Beneficiaries',
      icon: faUserFriends,
      link: '/beneficiaries',
    },
    {
      title: 'Media Library',
      icon: faPhotoVideo,
      link: '/media-library',
    },
    {
      title: 'Wysh Scheduling',
      icon: faImage,
      link: '/cards',
    },
    {
      title: 'Scheduled Wyshes',
      icon: faCalendarAlt,
      link: '/history',
    },
    {
      title: 'Help',
      icon: faQuestion,
      link: '/help',
    },
    {
      title: 'Support',
      icon: faHeadphones,
      link: '/support',
    },
  ];

  const userItems = [
    {
      title: 'Profile',
      icon: faUserCircle,
      link: '/user-profile',
    },
    {
      title: 'Plans',
      icon: faCheckCircle,
      link: '/plans',
    },
  ];

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {items.map(({ title, link, icon }) => {
          const active = getMenuItemActive(link, false);
          return (
            <AsideMenuItem
              key={title}
              active={active}
              title={title}
              icon={icon}
              link={link}
            />
          );
        })}
      </ul>
      <div className='aside-menu-list__devider'></div>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {userItems.map(({ title, link, icon }) => {
          const active = getMenuItemActive(link, false);
          return (
            <AsideMenuItem
              key={title}
              active={active}
              title={title}
              icon={icon}
              link={link}
            />
          );
        })}
        <li className='aside-menu-item' aria-haspopup='true'>
          <button className='aside-menu-item__link' onClick={logout}>
            <span className='aside-menu-item__icon'>
              <FontAwesomeIcon icon={faDoorOpen} />
            </span>
            <span className='aside-menu-item__text'>Logout</span>
          </button>
        </li>
      </ul>
    </>
  );
}
