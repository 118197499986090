import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_CITIES_REQUEST,
  CITIES_REQUEST_SUCCESS,
  CITIES_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* cityWatcher() {
  yield takeLatest(START_CITIES_REQUEST, cityWorker);
}

function cities(id) {
  return axios.get("api/cities", { params: { country_id: id } });
}

function* cityWorker(action) {
  try {
    const response = yield call(cities, action.payload);
    const data = response.data;

    yield put({ type: CITIES_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: CITIES_REQUEST_FAILED, error });
  }
}
