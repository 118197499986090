import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../API/axiosClient';
import { setLocalState } from '../../helpers/localStorage';
import {
  START_CHANGE_PROFILE_PHOTO_REQUEST,
  CHANGE_PROFILE_PHOTO_SUCCESS,
  CHANGE_PROFILE_PHOTO_FAILED,
} from '../actions/actionTypes';
import swal from 'sweetalert';

export function* changeProfilePhotoWatcher() {
  yield takeLatest(
    START_CHANGE_PROFILE_PHOTO_REQUEST,
    changeProfilePhotoWorker
  );
}

function changeProfilePhoto(payload) {
  console.log(...payload);
  return axios.post('api/change-image', payload);
}

function* changeProfilePhotoWorker(action) {
  try {
    const { data } = yield call(changeProfilePhoto, action.payload);

    if (data) {
      swal('Profile!', 'Profile Picture Updated successfully!', 'success').then(
        () => {
          setLocalState('@user', data.entity);
          window.location.reload();
        }
      );
    }

    yield put({ type: CHANGE_PROFILE_PHOTO_SUCCESS, data });
  } catch (error) {
    console.log(error);
    yield put({ type: CHANGE_PROFILE_PHOTO_FAILED, error });
  }
}
