import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { getLocalState } from '../../../../helpers/localStorage';
import { useDispatch } from 'react-redux';
import { mailConfirmationAction } from '../../../../redux/actions/Auth/mailConfirmation';
import { CircularProgress } from '@material-ui/core';
import toast from 'react-hot-toast';

function EmailActivation() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  React.useEffect(() => {
    const formData = new FormData();

    formData.append('Email', params.email);
    formData.append('ConfirmRegisterToken', params.code);
    dispatch(mailConfirmationAction(formData));
    setTimeout(() => {
      const data = getLocalState('@isConfirmed');
      if (data?.entity?.Status?.toLowerCase() === 'active') {
        history.replace('/auth/login');
      } else {
        toast.success('Email already verified, try to login');
        history.push('/auth/login');
      }
    }, 1500);
  }, []);

  return (
    <>
      {
        <div className='' style={{ display: 'block' }}>
          <div className='text-center mb-10 mb-lg-20'>
            <h3 className='font-size-h1'>Verifying your email...</h3>
            <br />
            <CircularProgress size={35} color='primary' />
          </div>
        </div>
      }
    </>
  );
}

export default injectIntl(EmailActivation);
