import {
  START_ADD_BENEFECIARY_REQUEST,
  ADD_BENEFECIARY_REQUEST_FAILED,
  ADD_BENEFECIARY_REQUEST_SUCCESS,
} from "../../actions/actionTypes";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

export function addBeneficiaryReducer(state = initialState, action) {
  switch (action.type) {
    case START_ADD_BENEFECIARY_REQUEST:
      return { ...state, fetching: true, error: null };
    case ADD_BENEFECIARY_REQUEST_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case ADD_BENEFECIARY_REQUEST_FAILED:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
}
