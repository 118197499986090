import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../../API/axiosClient';
import { setLocalState } from '../../../helpers/localStorage';
import {
  START_MAIL_CONFIRMATION_REQUEST,
  MAIL_CONFIRMATION_REQUEST_FAILED,
  MAIL_CONFIRMATION_REQUEST_SUCCESS,
} from '../../actions/actionTypes';

export function* mailConfirmationWatcher() {
  yield takeLatest(START_MAIL_CONFIRMATION_REQUEST, mailConfirmationWorker);
}

function mailConfirmation(data) {
  return axios.post('api/confirm-register', data);
}

function* mailConfirmationWorker(action) {
  try {
    const response = yield call(mailConfirmation, action.payload);
    const data = response.data;

    // setLocalState("@isConfirmed", data);
    // setLocalState("@user", data?.entity);
    // setLocalState("@token", data?.entity?.access_token);

    yield put({ type: MAIL_CONFIRMATION_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({
      type: MAIL_CONFIRMATION_REQUEST_FAILED,
      error: error?.response?.data,
    });
  }
}
