import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import Beneficiaries from './modules/Beneficiaries';
import BeneficiariesForm from './modules/Beneficiaries/BeneficiariesForm';
import EditBeneficiary from './modules/Beneficiaries/EditBeneficiary';
import DashboardPage from './modules/Dashboard/DashboardPage';
import UserProfile from './modules/UserProfile/UserProfilePage';
import MediaLibrary from './modules/MediaLibrary';
import Plans from './modules/Plans';
import WyshScheduling from './modules/WyshScheduling';
import ScheduledWyshes from './modules/ScheduledWyshes/ScheduledWyshes';
import ChangePassword from './modules/UserProfile/ChangePassword';
import AddMedia from './modules/MediaLibrary/AddMedia/AddMedia';
import HelpPage from './modules/Help/HelpPage';
import SupportPage from './modules/SupportPage';

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from='/' to='/dashboard' />
        }
        <ContentRoute path='/dashboard' component={DashboardPage} />
        <ContentRoute path='/user-profile' component={UserProfile} />
        <ContentRoute path='/beneficiaries' component={Beneficiaries} />
        <ContentRoute path='/media-library' component={MediaLibrary} />
        <ContentRoute path='/plans' component={Plans} />
        <ContentRoute path='/beneficiary-form' component={BeneficiariesForm} />
        <ContentRoute path='/beneficiary/:id' component={EditBeneficiary} />
        <ContentRoute path='/cards' component={WyshScheduling} />
        <ContentRoute path='/history' component={ScheduledWyshes} />
        <ContentRoute path='/help' component={HelpPage} />
        <ContentRoute path='/support' component={SupportPage} />
        <ContentRoute path='/change-pass' component={ChangePassword} />
        <ContentRoute path='/add-media' component={AddMedia} />

        <Redirect to='error/error-v1' />
      </Switch>
    </Suspense>
  );
}
