import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from '../../../../API/axiosClient';
import toast from 'react-hot-toast';
import './recommendation-form.scss';

const initialValues = {
  recipientEmail: '',
  recipientName: '',
  message: '',
};

function RecommendationForm() {
  const [loading, setLoading] = useState(false);

  const RecommendationSchema = Yup.object().shape({
    recipientEmail: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Recipient Email is required'),
    recipientName: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Recipient Name is required'),
    message: Yup.string()
      .min(3, 'Minimum 20 symbols')
      .max(50, 'Maximum 500 symbols')
      .required('Message is required'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RecommendationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      enableLoading();

      try {
        const { data } = await axios.post('/api/recommend', {
          email: values.recipientEmail,
          name: values.recipientName,
          body: values.message,
        });

        if (data.errorMsg) {
          toast.error(data?.errorMsg);
        } else {
          toast.success('Thanks for recommending eWysh');
          resetForm();
        }
      } catch (error) {
        toast.error('Something went wrong!');
      }

      setTimeout(() => {
        disableLoading();
        setSubmitting(false);
      }, 1500);
    },
  });

  return (
    <div className='recommendation-form-container'>
      <div className='recommendation-form-container__heading'>
        Recommend Ewysh to others
      </div>
      <form onSubmit={formik.handleSubmit} className='recommendation-form'>
        <div className='recommendation-form__half'>
          <div className='recommendation-form__input-group'>
            <input
              placeholder='Email'
              type='email'
              className={` ${getInputClasses('recipientEmail')}`}
              name='recipientEmail'
              {...formik.getFieldProps('recipientEmail')}
            />
            {formik.touched.recipientEmail && formik.errors.recipientEmail ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  {formik.errors.recipientEmail}
                </div>
              </div>
            ) : null}
          </div>
          <div className='recommendation-form__input-group'>
            <input
              placeholder='Recipient Name'
              type='text'
              className={` ${getInputClasses('recipientName')}`}
              name='recipientName'
              {...formik.getFieldProps('recipientName')}
            />
            {formik.touched.recipientName && formik.errors.recipientName ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  {formik.errors.recipientName}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className='recommendation-form__input-group'>
          <textarea
            placeholder='Your message...'
            type='text'
            className={` ${getInputClasses('recipientName')}`}
            name='message'
            rows='5'
            {...formik.getFieldProps('message')}
          />
          {formik.touched.message && formik.errors.message ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.message}</div>
            </div>
          ) : null}
        </div>

        <div className='recommendation-form__cta'>
          <button
            type='submit'
            disabled={formik.isSubmitting}
            className='recommendation-form__submit-button'
            style={{ background: '#a1cf5f', border: '1px solid #a1cf5f' }}
          >
            <span>Submit</span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default RecommendationForm;
