import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../API/axiosClient';
import {
  START_PAYMENT_REQUEST,
  PAYMENT_REQUEST_SUCCESS,
  PAYMENT_REQUEST_FAILED,
  PAYMENT_STATUS_REQUEST_SUCCESS,
} from '../actions/actionTypes';

export function* paymentWatcher() {
  yield takeLatest(START_PAYMENT_REQUEST, paymentWorker);
}

function stripeToken(data) {
  return axios.post('api/stripe-token', data);
}

function stripeStatus(data) {
  return axios.post('api/stripe-status', data);
}

function* paymentWorker(action) {
  const package_id = action.payload.package_id;
  const payment_frequency = action.payload.payment_frequency;

  try {
    const stripeData = { package_id, payment_frequency };
    const { data } = yield call(stripeToken, stripeData);

    yield put({ type: PAYMENT_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: PAYMENT_REQUEST_FAILED, error });
  }
}
