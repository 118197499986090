import { combineReducers } from 'redux';
import { loginReducer } from './reducers/Auth/login';
import { registerReducer } from './reducers/Auth/register';
import { forgetPasswordReducer } from './reducers/Auth/forgetPassword';
import { confirmForgetTokenReducer } from './reducers/Auth/confirmForgetToken';
import { resetPasswordReducer } from './reducers/Auth/resetPassword';
import { mailConfirmationReducer } from './reducers/Auth/mailConfirmation';
import { getCountriesReducer } from './reducers/getCountries';
import { getCountryCitiesReducer } from './reducers/getCountryCities';
import { getBeneficiariesReducer } from './reducers/Beneficiaries/getBeneficiaries';
import { addBeneficiaryReducer } from './reducers/Beneficiaries/addBeneficiary';
import { editBeneficiaryReducer } from './reducers/Beneficiaries/editBeneficiary';
import { deleteBeneficiaryReducer } from './reducers/Beneficiaries/deleteBeneficiary';
import { getRelationsReducer } from './reducers/Beneficiaries/getRelations';
import { editProfileReducer } from './reducers/editProfile';
import { getMediaReducer } from './reducers/Media/getMedia';
import { addMediaReducer } from './reducers/Media/addMedia';
import { deleteMediaReducer } from './reducers/Media/deleteMedia';
import { getPackagesReducer } from './reducers/getPackages';
import { paymentReducer } from './reducers/payment';
import { paymentStatusReducer } from './reducers/paymentStatus';
import { stripePaymentStatusReducer } from './reducers/stripePaymentStatus';
import { getSubscribtionInfoReducer } from './reducers/getSubscribtionInfo';
import { sendCardReducer } from './reducers/sendCard';
import { listCardsReducer } from './reducers/listCards';
import { previewECardReducer } from './reducers/previewECard';
import { deleteECardReducer } from './reducers/deleteECard';
import { confirmDeacesedReducer } from './reducers/confirmDeacesed';
import { changePasswordReducer } from './reducers/changePassword';
import { changeProfilePhotoReducer } from './reducers/changeProfilePhoto';

import { getNOKUsersReducer } from './reducers/getNOKUsers';
import { sendSupportReducer } from './reducers/sendSupport';

export const rootReducer = combineReducers({
  login: loginReducer,
  register: registerReducer,
  forgetPassword: forgetPasswordReducer,
  confirmForgetToken: confirmForgetTokenReducer,
  resetPassword: resetPasswordReducer,
  mailConfirm: mailConfirmationReducer,
  countries: getCountriesReducer,
  cities: getCountryCitiesReducer,
  beneficiaries: getBeneficiariesReducer,
  addBeneficiary: addBeneficiaryReducer,
  editBeneficiary: editBeneficiaryReducer,
  deleteBeneficiary: deleteBeneficiaryReducer,
  relations: getRelationsReducer,
  editProfile: editProfileReducer,
  getMedia: getMediaReducer,
  addMedia: addMediaReducer,
  deleteMedia: deleteMediaReducer,
  packages: getPackagesReducer,
  payment: paymentReducer,
  paymentStatus: paymentStatusReducer,
  stripePaymentStatus: stripePaymentStatusReducer,
  subscribtionInfo: getSubscribtionInfoReducer,
  sendCard: sendCardReducer,
  listCards: listCardsReducer,
  previewECard: previewECardReducer,
  deleteECard: deleteECardReducer,
  confirmDeacesed: confirmDeacesedReducer,
  changePassword: changePasswordReducer,
  changeProfilePhoto: changeProfilePhotoReducer,
  NOKUsers: getNOKUsersReducer,
  sendSupport: sendSupportReducer,
});
