import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getCountriesAction } from '../../../../redux/actions/getCountries';
import { getCountryCitiesAction } from '../../../../redux/actions/getCountryCities';
import { registerAction } from '../../../../redux/actions/Auth/register';
import { getLocalState } from '../../../../helpers/localStorage';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  confirmpassword: '',
  state: '',
  postalcode: '',
  street1: '',
  street2: '',
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [shouldProceed, setShouldProceed] = useState(false);
  const [value, setValue] = useState('');

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getCountriesAction());
  }, [dispatch]);

  const countries = useSelector((state) => state.countries);
  const cities = useSelector((state) => state.cities);
  const register = useSelector((state) => state.register);

  const RegistrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    lastname: Yup.string()
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    phone: Yup.number()
      .typeError('You must specify a number, letters not allowed')
      .min(9, 'Minimum 9 numbers')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string()
      .min(6, 'Minimum 6 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    confirmpassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match"
        ),
      }),
    postalcode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    state: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    street1: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    // street2: Yup.string().required(
    //   intl.formatMessage({
    //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //   })
    // ),
    acceptTerms: Yup.bool().required(
      'You must accept the terms and conditions'
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    const id = e.target.value;

    if (id) {
      dispatch(getCountryCitiesAction(parseInt(id)));
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!country) {
        toast.error('Please choose country');
        return '';
      }

      if (!city) {
        toast.error('Please choose city');
        return '';
      }

      if (!values.acceptTerms) {
        toast.error('You should agree on the terms & conditions');
        return '';
      }

      enableLoading();
      setSubmitting(true);
      const formData = new FormData();

      formData.append('FirstName', values.firstname);
      formData.append('last_name', values.lastname);
      formData.append('phone', values.phone);
      formData.append('Email', values.email);
      formData.append('State', values.state);
      formData.append('PostalCode', values.postalcode);
      formData.append('StreetOne', values.street1);
      formData.append('StreetTwo', values.street2);
      formData.append('Password', values.password);
      formData.append('PasswordConfirmation', values.confirmpassword);
      formData.append('acceptTerms', values.acceptTerms);
      formData.append('CountryID', parseInt(country));
      formData.append('CityID', parseInt(city));
      formData.append(
        'SubscribedToList',
        values.SubscribedToList === true ? 1 : 0
      );
      dispatch(registerAction(formData));

      setTimeout(() => {
        setSubmitting(false);
        disableLoading();
        const registerData = getLocalState('@register');

        if (registerData?.code === 200) {
          toast.success(
            'Successful registration, Please check you email to verify your account'
          );
          history.push('/auth/login');
        } else {
          // alert(1);  
        }
      }, 5000);
    },
  });

  const { setTouched, errors, validateForm } = formik;
  const validateStepOne = async () => {
    setTouched({
      firstname: true,
      lastname: true,
      email: true,
      phone: true,
      password: true,
      confirmpassword: true,
    });
    await validateForm();
    setShouldProceed(true);
  };

  React.useEffect(() => {
    if (shouldProceed) {
      if (
        errors.firstname ||
        errors.lastname ||
        errors.email ||
        errors.phone ||
        errors.password ||
        errors.confirmpassword
      ) {
        setShouldProceed(false);
        return;
      }
      setStep(2);
    }
  }, [errors, shouldProceed]);

  return (
    <div className='login-form login-signin' style={{ display: 'block' }}>
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.REGISTER.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          Enter your details to create your account
        </p>
      </div>

      <form
        id='kt_login_signin_form'
        className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {register?.error && (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>
              {register.error.errorMsg}
            </div>
          </div>
        )}
        {/* end: Alert */}
        {step === 1 && (
          <>
            {/* begin: firstname */}
            <div className='form-group fv-plugins-icon-container'>
              <input
                placeholder='First name'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'firstname'
                )}`}
                name='firstname'
                {...formik.getFieldProps('firstname')}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.firstname}</div>
                </div>
              ) : null}
            </div>
            <div className='form-group fv-plugins-icon-container'>
              <input
                placeholder='Last name'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'lastname'
                )}`}
                name='lastname'
                {...formik.getFieldProps('lastname')}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.lastname}</div>
                </div>
              ) : null}
            </div>
            {/* end: Fullname */}

            {/* begin: Email */}
            <div className='form-group fv-plugins-icon-container'>
              <input
                placeholder='Email'
                type='email'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'email'
                )}`}
                name='email'
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            {/* end: Email */}

            {/* begin: Username */}
            <div className='form-group fv-plugins-icon-container'>
              <input
                placeholder='Phone Number'
                type='tel'
                pattern='^[0–9]$'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'phone'
                )}`}
                name='phone'
                value={value}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setValue(e.target.value);
                  }
                }}
                {...formik.getFieldProps('phone')}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              ) : null}
            </div>
            {/* end: Username */}

            {/* begin: Password */}
            <div className='input-group fv-plugins-icon-container my-4'>
              <input
                placeholder='Password'
                type={showPassword === true ? 'text' : 'password'}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'password'
                )}`}
                name='password'
                {...formik.getFieldProps('password')}
              />
              <div className='input-group-prepend'>
                <span
                  className='input-group-text'
                  style={{ border: 'none' }}
                  onClick={() =>
                    setShowPassword(showPassword === true ? false : true)
                  }
                >
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/Hidden.svg')}
                  />
                </span>
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.password}</div>
              </div>
            ) : null}
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <div className='input-group fv-plugins-icon-container  my-5'>
              <input
                placeholder='Confirm Password'
                type={showPassword === true ? 'text' : 'password'}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'confirmpassword'
                )}`}
                name='confirmpassword'
                {...formik.getFieldProps('confirmpassword')}
              />
              <div className='input-group-prepend'>
                <span
                  className='input-group-text'
                  style={{ border: 'none' }}
                  onClick={() =>
                    setShowPassword(showPassword === true ? false : true)
                  }
                >
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/Hidden.svg')}
                  />
                </span>
              </div>
            </div>
            {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  {formik.errors.confirmpassword}
                </div>
              </div>
            ) : null}
          </>
        )}
        {step === 2 && (
          <>

          <div className='form-group fv-plugins-icon-container'>
              <input
                placeholder='Street 1'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'street1'
                )}`}
                name='street1'
                {...formik.getFieldProps('street1')}
              />
              {formik.touched.street1 && formik.errors.street1 ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.street1}</div>
                </div>
              ) : null}
            </div>

            <div className='form-group fv-plugins-icon-container'>
              <input
                placeholder='Street 2'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'street2'
                )}`}
                name='street2'
                {...formik.getFieldProps('street2')}
              />
              {formik.touched.street2 && formik.errors.street2 ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.street2}</div>
                </div>
              ) : null}
            </div>
            <div className='form-group fv-plugins-icon-container'>
              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'country'
                )}`}
                name='country'
                onChange={(e) => handleCountryChange(e)}
                value={country}
              >
                <option value=''>Select Country</option>
                {countries?.data?.entityList?.map((country) => {
                  return (
                    <option key={country.Identifier} value={country.Identifier}>
                      {country.Name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.country && formik.errors.country ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.country}</div>
                </div>
              ) : null}
            </div>

            <div className='form-group fv-plugins-icon-container'>
              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'city'
                )}`}
                name='city'
                onChange={(e) => handleCityChange(e)}
              >
                <option value=''>Select Region</option>
                {cities?.data?.entityList?.map((city) => {
                  return (
                    <option key={city.Identifier} value={city.Identifier}>
                      {city.Name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.city && formik.errors.city ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.city}</div>
                </div>
              ) : null}
            </div>

            <div className='form-group fv-plugins-icon-container'>
              <input
                placeholder='City'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'state'
                )}`}
                name='state'
                {...formik.getFieldProps('state')}
              />
              {formik.touched.state && formik.errors.state ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.state}</div>
                </div>
              ) : null}
            </div>

            <div className='form-group fv-plugins-icon-container'>
              <input
                placeholder='Postal code'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'postalcode'
                )}`}
                name='postalcode'
                {...formik.getFieldProps('postalcode')}
              />
              {formik.touched.postalcode && formik.errors.postalcode ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors.postalcode}
                  </div>
                </div>
              ) : null}
            </div>

            {/* end: Confirm Password */}

            {/* begin: Terms and Conditions */}
            <div className='form-group'>
              <label className='checkbox'>
                <input
                  type='checkbox'
                  name='acceptTerms'
                  className='m-1'
                  {...formik.getFieldProps('acceptTerms')}
                />
                <span />
                <Link
                  onClick={() => handleShow()}
                  className='mr-1 termsAndCondition'
                  rel='noopener noreferrer'
                  style={{ marginLeft: '0.6rem' }}
                >
                  I agree the Terms & Conditions
                </Link>
              </label>
              <span className='text-muted'>
                You must accept Terms & Conditions before submit
              </span>
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors.acceptTerms}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Terms and Conditions */}
            {/* begin : Add to subscribe list */}
            <div className='form-group'>
              <label className='checkbox'>
                <input
                  type='checkbox'
                  name='SubscribedToList'
                  className='m-1'
                  {...formik.getFieldProps('subscirbe')}
                />
                <span />

                <div
                  className='mr-1'
                  rel='noopener noreferrer '
                  style={{ marginLeft: '0.6rem' }}
                >
                  Accept marketing communication from ewysh.
                </div>
              </label>
            </div>
          </>
        )}
        {/* end : Add to subscribe list */}
        <div className='form-group d-flex flex-wrap flex-center'>
          {shouldProceed && (
            <button
              type='submit'
              disabled={formik.isSubmitting}
              className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'
              style={{ background: '#a1cf5f', border: '1px solid #a1cf5f' }}
            >
              <span>Register now</span>
              {loading && <span className='ml-3 spinner spinner-white'></span>}
            </button>
          )}
          {step === 1 && (
            <button
              type='button'
              className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'
              style={{ background: '#a1cf5f', border: '1px solid #a1cf5f' }}
              onClick={validateStepOne}
            >
              <span>Next</span>
            </button>
          )}

          {step === 2 && (
            <button
              type='button'
              className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4'
              onClick={() => setStep(1)}
            >
              Back
            </button>
          )}

          <Link to='/auth/login'>
            <button
              type='button'
              className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4'
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body>
          <p>
            I confirm I accept these Terms and Condition of Service and I agree
            to create an account which holds my personal data and information on
            my nominated Beneficiaries and Next of Kin. I confirm that I also
            accept the Privacy Policy in regards to how my data and any data I
            provide on others, is treated.
          </p>
          <p>
            <strong>
              <u>Terms and Condition of Service</u>
            </strong>
          </p>
          <ol>
            <li>
              <strong>Definitions:</strong>
            </li>
          </ol>
          <ul>
            <li>
              &lsquo;Beneficiary&rsquo; or &lsquo;Beneficiaries&rsquo; means the
              person(s) the Client nominate to receive Media from the Client.
            </li>
            <li>
              &lsquo;Contract&rsquo; means the Contract in exchange for the
              Services entered into by the Client and the Service Provider
              (together &lsquo;The Parties&rsquo;). This includes these Terms
              and Conditions of Service, the agreed subscription cost, and
              incorporates any other documents The Parties enter into, including
              but not limited to the data privacy policy, any cookie policy on
              the Service Providers website, any update to the Terms and
              Conditions of Service and any other documents the Client agrees to
              enter with the Service Provider.
            </li>
            <li>
              &lsquo;Client&rsquo; means the person using and/or subscribing to
              the website or application, who may or may not create Media,
              whether subscribing to the Services or not.
            </li>
            <li>
              &lsquo;Client Responsibilities&rsquo; means items that are solely
              the responsibility of the Client and not the responsibility of
              Ewysh, for which the Client holds no liabilities over the Service
              Provider for.
            </li>
            <li>
              &lsquo;Ewysh&rsquo; and &lsquo;ewysh.com&rsquo; means the website
              or application created by the Service Provider.
            </li>
            <li>
              &lsquo;Fee&rsquo; or &lsquo;Fees&rsquo; means the subscription fee
              paid to Afterlife Intl Limited or their nominated payment
              collection company in exchange for the Services, regardless of
              whether they are paid monthly, yearly or any other frequency
              offered by the Service Provider.
            </li>
            <li>
              &lsquo;Next of Kin&rsquo; or &lsquo;NOK&rsquo; means the person
              the Client nominates to assist with the delivery of Media to a
              Beneficiary. The Next of Kin or NOK may also be a Beneficiary, if
              nominated.
            </li>
            <li>
              &lsquo;Media&rsquo; means any media content or information created
              or saved by the Client, whether that be in video, photo, text or
              graphic format, and whether that be assigned to a Beneficiary or
              not.
            </li>
            <li>
              &lsquo;Package&rsquo; means the selected subscription agreed with
              the Client, which forms part of this Contract.
            </li>
            <li>
              &lsquo;Service Provider&rsquo; means Afterlife Intl Limited, a
              company registered in England and Wales with the company number
              &lsquo;12984429&rsquo;.
            </li>
          </ul>
          <ol start='2'>
            <li>
              <strong>Fees</strong>
              <ol>
                <li>
                  The Client agrees to procure to the Service Provider the Fee
                  due at each interval as set out in the subscription, depending
                  on the Package requested. The Client agrees that the Fee will
                  be taken for his or her nominated card, upon accepting these
                  Terms and Conditions of Service and at each agreed interval as
                  per the subscription, unless the Client passes away or cancels
                  the Contract.
                </li>
                <li>
                  The Client understands this is a rolling subscription and has
                  no right to request a refund from a card provider. If the
                  Client no longer wishes to utilise Ewysh, the Client should
                  provide a notice of termination as per clause 6.b.
                </li>
                <li>
                  Should the Client request a different Package, the Fees may
                  increase or decrease, depending on the Subscription. Should
                  the Client increase their subscription, the associated Fee
                  will become payable on the Client accepting the Terms and
                  Condition of Service for the said Package.
                </li>
                <li>
                  If the event the Client wants to reduce his or her
                  subscription, 60 days&rsquo; notice shall be given, and Fees
                  until then shall remain payable for this period.
                </li>
                <li>
                  No refund of Fees are offered, implied or given, whether or
                  not the Client utilises the Services and/or creates Media.
                </li>
                <li>
                  The Service Provider can increase the Fees from time to time
                  and reasonable notice shall be given to the Client. Should the
                  Client reject such increase, the Client can terminate and the
                  60 day notice period shall be reduced to 14 days.
                </li>
              </ol>
            </li>
            <li>
              <strong>Services:</strong>
            </li>
            <li>
              In exchange for the agreed monthly subscription fee, the Service
              Provider shall provide the Client with:
              <ol>
                <li>
                  access to Ewysh and store Media to leave to Beneficiaries.
                </li>
                <li>
                  Delivery of Media to the Beneficiaries as instructed by the
                  Client, where possible.
                </li>
              </ol>
            </li>
          </ol>
          <ul>
            <li>log in credentials in order to access to Ewysh.</li>
          </ul>
          <ol>
            <li>access to tool to assist with the creation of Media.</li>
            <li>
              To provide a strategy to identify when a Client has passed away,
              and to then activate the Media created.
            </li>
            <li>
              To provide a chatbot helpdesk to assist with Client lead queries.
            </li>
          </ol>
          <ul>
            <li>
              Ewysh will take reasonable endeavours to ascertain if the Client
              has passed away.
            </li>
          </ul>
          <ol start='4'>
            <li>
              <strong>Client Responsibilities:</strong>
            </li>
            <li>
              The Client is responsible for ensuring:
              <ol>
                <li>
                  any Media created complies with local laws and is not in any
                  way illegal or reporting of historic illegal or potentially
                  illegal acts which may have taken place.
                </li>
                <li>
                  their personal details are kept up to date, including but not
                  limited to payment details.
                </li>
              </ol>
            </li>
          </ol>
          <ul>
            <li>
              the NOK is informed that they are being nominated as someone to
              assist with the delivery of their personal Media, including
              assisting the Service Provider with knowing when they may have
              passed away.
            </li>
          </ul>
          <ol>
            <li>
              Ensuring the details of their Beneficiaries is kept up to date,
              including but not limited to the Media created, the delivery date
              of the Media and the registered email address for the delivery of
              Media.
            </li>
            <li>
              Ensuring that sensitive personal details, such as dates of birth,
              home addresses of the Beneficiary are not provided to the Service
              Provider, and if the Client does provide such details, the Client
              takes provides the Service Provider with a full indemnity from the
              Beneficiary and understands that the Service Provider may not
              deliver the Media if the Beneficiary opts out of receiving emails
              from Ewysh.
            </li>
            <li>
              He/she doesn&rsquo;t stop the payment without notice as per clause
              6.b. and accepts that stopping the payments without following the
              cancellation procedure may trigger the delivery of Media prior to
              the Client passing away, as Ewysh uses this as one the indicators
              to identify when a Client has passed away.
            </li>
          </ol>
          <ul>
            <li>
              Alerting the NOK that he/she will need to take on these
              responsibilities on their behalf should they pass away.
            </li>
            <li>
              That they understand they may lose Media stored on Ewysh if they
              terminate the Contract.
            </li>
          </ul>
          <ol>
            <li>
              The Client accepts that failure of to procure the Fees (except
              where the NOK has informed us of them passing away) may result in:
              <ol>
                <li>
                  the Services being suspended or terminated (including access
                  to Ewysh).
                </li>
                <li>
                  their Media may be delivered as Ewysh may believe that the
                  Client has passed away.
                </li>
              </ol>
            </li>
            <li>
              <strong>Limitations on Liability</strong>
            </li>
            <li>
              The service Provider accepts no liability for:
              <ol>
                <li>
                  Wrong delivery of Media if the Client has not kept his/her
                  Ewysh portal up to date, including but not limited to payment,
                  Beneficiary and NOK details.
                </li>
                <li>
                  Media shared with law enforcement agencies at their request.
                </li>
              </ol>
            </li>
          </ol>
          <ul>
            <li>Media once termination has been requested by the Client.</li>
          </ul>
          <ol>
            <li>Loss of Media by third parties.</li>
            <li>
              Media being delivered if the Service Provider suspects the Client
              has passed away and the NOK has failed to inform Ewysh.
            </li>
            <li>
              Anything outside of its control, whatsoever, including but not
              limited to negligence, tort and data breaches by third parties.
            </li>
          </ol>
          <ol start='6'>
            <li>
              <strong>Term and Termination</strong>
              <ol start='6'>
                <li>
                  The Contract to receive the Services is a rolling Contract
                  with a monthly or yearly Fee applicable. The Terms and
                  Conditions of Service will remain in place until the earliest
                  of. (i) the Client passing away, (ii) the Client terminating
                  in accordance with clause 6.b, or (iii) the Service Provider
                  withdrawing the Services (for any reason whatsoever).
                </li>
                <li>
                  The Client agrees that it will promptly inform the Service
                  Provider that it wishes to terminate by providing 60
                  days&rsquo; notice to{' '}
                  <a href='mailto:info@ewysh.com'>info@ewysh.com</a>. The Client
                  also accepts that yearly Fees are non-refundable given the
                  discount already offered to yearly Subscriptions.
                </li>
                <li>
                  In the event of the Service Provider ceasing trading, the NOK
                  will be notified and will have a reasonable amount of time to
                  download all Media to ensure he/she can pass such Media to
                  your Beneficiaries as and when they see fit.
                </li>
                <li>
                  The Service Provider retains the right to update these Terms
                  and Conditions of Service from time to time and the Client
                  will be notified in a reasonable time frame, whether that be
                  by email, when logging into their account, having the visible
                  on the Service Providers website, or by any other method
                  deemed suitable.
                </li>
              </ol>
            </li>
            <li>
              <strong>Miscellaneous</strong>
              <ol>
                <li>
                  The Service Provider shall be entitled to assign the benefit
                  of this Contract or to sub-Contract and/or delegate its
                  obligations hereunder to any company it sees fit, including
                  but not limited to solvent amalgamations with other companies
                  and transferring the ownership of the website to other
                  companies as the Directors of the Service Provider see fit, to
                  the extent that the Services can be fulfilled.
                </li>
                <li>
                  Where the word 'including' is used in these terms the words
                  'without limitation' will be inferred.
                </li>
                <li>
                  Unless the context otherwise requires, words in the singular
                  shall include the plural and vice versa.
                </li>
                <li>
                  Unless the context otherwise requires, references to
                  &lsquo;him&rsquo; or &lsquo;her&rsquo; shall also mean
                  references to all sexes.
                </li>
                <li>
                  The Client has no rights alter these Terms and Conditions of
                  Service.
                </li>
                <li>
                  In the event the Services Provider updates the Terms and
                  Conditions of Service, the Client has the right to reject such
                  change and terminate. Any Fees collected beyond this date will
                  be refunded from the date the termination request was
                  received.
                </li>
                <li>
                  The Client acknowledges that he/she is entering into this
                  Contract and has not relied upon and shall have no right or
                  remedy in respect of, any statement, representation, assurance
                  or warranty (whether made orally or otherwise and including
                  negligently or innocently), other than as expressly set out in
                  this Contract. Nothing herein shall limit or exclude liability
                  for fraud.
                </li>
                <li>
                  All provisions of this Agreement that may reasonably be
                  construed as surviving the expiration, termination or
                  cancellation of this Agreement, including (without limitation)
                  any payment obligation or data required for legal purposes,
                  shall survive such event and continue to bind the Clients.
                </li>
                <li>
                  A person who is not a party to this Agreement, including but
                  not limited to a NOK and/or Beneficiary shall not have any
                  rights under or in connection with it.
                </li>
                <li>
                  If any provision of this Agreement (or part of any provision)
                  is found by any court or other body of competent jurisdiction
                  to be invalid, unenforceable or illegal, the other provisions
                  shall remain in force. If any invalid, unenforceable or
                  illegal provision would be valid, enforceable and/or legal if
                  some part of it were deleted, the provision shall apply with
                  whatever modification is necessary to give effect to the
                  commercial intention of The Parties.
                </li>
              </ol>
            </li>
            <li>
              <strong>Governance:</strong>
              <ol>
                <li>
                  This Contract shall be governed by and all disputes arising
                  out of or relating to this Contract shall be governed by the
                  laws of England and Wales and The Parties hereby submit to the
                  exclusive jurisdiction of the English courts.
                </li>
              </ol>
            </li>
          </ol>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default injectIntl(Registration);
