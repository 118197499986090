import { all } from 'redux-saga/effects';

import { loginWatcher } from './sagas/Auth/login';
import { registerWatcher } from './sagas/Auth/register';
import { forgetPasswordWatcher } from './sagas/Auth/forgetPassword';
import { confirmForgetTokenWatcher } from './sagas/Auth/confirmForgetToken';
import { resetPasswordWatcher } from './sagas/Auth/resetPassword';
import { mailConfirmationWatcher } from './sagas/Auth/mailConfirmation';
import { countryWatcher } from './sagas/getCountries';
import { cityWatcher } from './sagas/getCountryCities';
import { beneficiaryWatcher } from './sagas/Beneficiaries/getBeneficiaries';
import { addBeneficiaryWatcher } from './sagas/Beneficiaries/addBeneficiary';
import { editBeneficiaryWatcher } from './sagas/Beneficiaries/editBeneficiary';
import { deleteBeneficiaryWatcher } from './sagas/Beneficiaries/deleteBeneficiary';
import { getRelationsWatcher } from './sagas/Beneficiaries/getRelations';
import { editProfileWatcher } from './sagas/editProfile';
import { getMediaWatcher } from './sagas/Media/getMedia';
import { addMediaWatcher } from './sagas/Media/addMedia';
import { deleteMediaWatcher } from './sagas/Media/deleteMedia';
import { packagesWatcher } from './sagas/getPackages';
import { paymentWatcher } from './sagas/payment';
import { paymentStatusWatcher } from './sagas/paymentStatus';
import { getSubscribtionInfoWatcher } from './sagas/getSubscribtionInfo';
import { sendCardWatcher } from './sagas/senCard';
import { listCardsWatcher } from './sagas/listCards';
import { previewECardWatcher } from './sagas/previewECard';
import { deleteECardWatcher } from './sagas/deleteECard';
import { confirmDeacesedWatcher } from './sagas/confirmDeacesed';
import { chnagePasswordWatcher } from './sagas/changePassword';
import { NOKusersWatcher } from './sagas/getNOKUsers';
import { changeProfilePhotoWatcher } from './sagas/changeUserProfilePhoto';
import { stripePaymentStatusWatcher } from './sagas/stripePaymentStatus';
import { sendSupportWatcher } from './sagas/sendSupport';

export function* rootSaga() {
  yield all([
    loginWatcher(),
    countryWatcher(),
    cityWatcher(),
    registerWatcher(),
    mailConfirmationWatcher(),
    forgetPasswordWatcher(),
    resetPasswordWatcher(),
    confirmForgetTokenWatcher(),
    beneficiaryWatcher(),
    getRelationsWatcher(),
    addBeneficiaryWatcher(),
    editBeneficiaryWatcher(),
    deleteBeneficiaryWatcher(),
    editProfileWatcher(),
    getMediaWatcher(),
    addMediaWatcher(),
    deleteMediaWatcher(),
    packagesWatcher(),
    paymentWatcher(),
    paymentStatusWatcher(),
    getSubscribtionInfoWatcher(),
    sendCardWatcher(),
    listCardsWatcher(),
    previewECardWatcher(),
    deleteECardWatcher(),
    confirmDeacesedWatcher(),
    chnagePasswordWatcher(),
    NOKusersWatcher(),
    stripePaymentStatusWatcher(),
    changeProfilePhotoWatcher(),
    sendSupportWatcher(),
  ]);
}
