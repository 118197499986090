import React from 'react';
import { Accordion , Card , Button} from "react-bootstrap";

const HelpPage = (props) => {

  return (
    <>
    <Accordion>
        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <h4 className='accordion-head'> How to create a Wysh?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
            <Card.Body>
                <ul>
                    <li>From Home Screen, click on “Wysh Scheduling”</li>
                    <li>Select beneficiary from your list</li>
                    <li>Start creating your Wysh</li>
                    <li>It is recommended to type your “full name” and “reason for the Wysh in the subject line</li>
                    <li>Select files from library and attach it to Wysh</li>
                    <li>Select date to be sent using specific date or after death</li>
                    <li>Save created Wysh</li>
                    <li>Created Wysh will be added to your scheduled Wyshes list</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="1">
              <h4 className='accordion-head'>How to create or add files to my Library?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
            <Card.Body>
                <ul>
                    <li>From Home Screen, click on “Media Library”</li>
                    <li>Click on “Upload Media” to upload Media file from local drive</li>
                    <li>Click on “Upload Photo” to upload photo or documents from local drive</li>
                    <li>Click on “Record Video” to record Video using Computer or Phone Camera, keep your eyes at the camera and start recording </li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                <h4 className='accordion-head'>How to delete files from my Library?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
            <Card.Body>
                <ul>
                    <li>From Home Screen, click on “Media Library”</li>
                    <li>Browse to targeted file for deletion </li>
                    <li>Click delete “Upload Media” to upload Media file from local drive</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                <h4 className='accordion-head'>How to Edit Scheduled Wyshes?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
            <Card.Body>
                <ul>
                    <li>From Home Screen, click on “Scheduled Wyshes”</li>
                    <li>Browse for targeted Wysh</li>
                    <li>Click Delete</li>
                    <li>Start new Wysh</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="4">
                <h4 className='accordion-head'>How to update my personal info?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
            <Card.Body>
                <ul>
                    <li>From Home Screen, click on “Profile”</li>
                    <li>Change or update personal info</li>
                    <li>Click update</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="5">
                <h4 className='accordion-head'>How to create a Beneficiary?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
            <Card.Body>
                <ul>
                    <li>From Home Screen, click on “Beneficiaries”</li>
                    <li>Click on “Add Beneficiary”</li>
                    <li>Complete required information</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="6">
                <h4 className='accordion-head'>Will my beneficiary notify after being added to my list?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
            <Card.Body>
                <p>No, beneficiary(s) will not be notified upon adding him/her to beneficiaries list </p>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="7">
                <h4 className='accordion-head'>Will my beneficiary notify after being added to my list?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="7">
            <Card.Body>
                <p>No, beneficiary(s) will not be notified upon adding him/her to beneficiaries list </p>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="8">
                <h4 className='accordion-head'>How to update Beneficiary’s information?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="8">
            <Card.Body>
                <ul>
                    <li>From Home Screen, click on “Beneficiaries”</li>
                    <li>Select from list and click on “Edit”</li>
                    <li>Update Beneficiary’ information as desired</li>
                    <li>Click update</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="9">
                <h4 className='accordion-head'>How to assign my Next of Kin?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="9">
            <Card.Body>
                <p><strong>1- New Beneficiary:</strong></p>
                <ul>
                    <li>From Home screen, click on “Beneficiaries”</li>
                    <li>Add new Beneficiary</li>
                    <li>Complete the information fields</li>
                    <li>A link will be emailed to elect Next of Kin inviting him/her open account with Ewysh. Next of Kin Account will include a tab allows him/her to communicate your death</li>
                </ul>
                <br/>
                <p><strong>2- Existing Beneficiary:</strong></p>
                <ul>
                    <li>From Home Screen, click on “Beneficiaries”</li>
                    <li>Select from list and click on “Edit”</li>
                    <li>Click on “Make Next of Kin”</li>
                    <li>Click update</li>
                    <li>A link will be emailed to elect Next of Kin inviting him/her open account with Ewysh. Next of Kin Account will include a tab allows him/her to communicate your death </li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="10">
                <h4 className='accordion-head'>How to change or replace my Next of Kin?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="10">
            <Card.Body>
                <ul>
                    <li>From Home screen click on “Beneficiaries”</li>
                    <li>Select already assigned Next of Kin and Click on Delete</li>
                    <li>Add New Beneficiary and complete information fields</li>
                    <li>Add New Beneficiary and complete information fields</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="11">
                <h4 className='accordion-head'>Can I have more than one Next of Kin?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="11">
            <Card.Body>
                <p>Yes, you can assign more than one of Next of Kin, if any of assigned Next of Kin(s) Communicated with Ewysh, his/her communication will be treated as formal notification with no wait time for remaining Next of Kins’ communication </p>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="12">
                <h4 className='accordion-head'>What is the process to confirm death?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="12">
            <Card.Body>
                <ul>
                    <li>The assigned Next of Kin will notify Ewysh by Login to his/her Ewysh account, click on confirm death, enter date and place of death</li>
                    <li>Ewysh will sent a notification email to Ewysh subscriber advising account holder that a confirmation of death has been received and advise account holder to login to his/her account asap if that death notification was not true</li>
                    <li>Ewysh to monitor account for one week and if no activity is observed will proceed with release Scheduled Wyshes saved.</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="13">
                <h4 className='accordion-head'>Will my Next billed for being assigned as Next of Kin?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="13">
            <Card.Body>
                <p>No, Next of Kin account is limited to confirm subscriber death, therefore no charge will be added. </p>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="15">
                <h4 className='accordion-head'>How to upgrade my account?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="15">
            <Card.Body>
                <ul>
                    <li>From Home Screen, under Your Plan Click on “Upgrade”</li>
                    <li>Follow the link and upgrade to desired plan offered</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="16">
                <h4 className='accordion-head'>What will happen to my account after death being confirmed?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="16">
            <Card.Body>
                <ul>
                    <li>Account will be converted to “Freeze Account” where all links are disabled</li>
                    <li>Scheduled Wyshes previously created will be sent according to Subscriber elected terms</li>
                    <li>With the release of last Wysh or Account reaches its life limit, the account will be completely deleted</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        
        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="17">
                <h4 className='accordion-head'>Will my account continue to be billed after death being confirmed?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="17">
            <Card.Body>
                <p>No; the account will be converted to “Freeze Account” and no further billing or charges will occur</p>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="18">
                <h4 className='accordion-head'>Account Cancelation</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="18">
            <Card.Body>
            <p><strong>1- No Payments:</strong></p>
                <ul>
                    <li>Ewysh account will be cancelled in the event subscription fees are not paid on due date. </li>
                    <li>If account holder do not bring account to current, all previously saved Wyshes and Media Library files will be deleted </li>
                </ul>
                <br/>
                <p><strong>2- Account Holder request</strong></p>
                <ul>
                    <li>Please contact Ewysh by click on “Contact us”</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

        
        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="19">
                <h4 className='accordion-head'>How to increase Storage space and/or add more Wyshes?</h4>
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="19">
            <Card.Body>
                <ul>
                    <li>How to increase Storage space and/or add more Wyshes</li>
                    <li>If more storage space and/or increase number of Wyshes beyond Gold plan is needed; please contact Ewysh by click contact and complete your request</li>
                </ul>
            </Card.Body>
            </Accordion.Collapse>
        </Card>

    </Accordion>
    </>
  );
};

export default HelpPage;
