import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { paymentAction } from '../../../../redux/actions/payment';
import { getPackagesAction } from '../../../../redux/actions/getPackages';
import PaymentPage from '../../Plans/paymentPage';

const NotSubscripedUserCard = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(0);

  const closePaymentModal = () => setShow(false);

  const openPaymentModal = () => {
    setShow(true);
    handlePayment();
  }

  const packages = useSelector((state) => state.packages);
  const payment = useSelector((state) => state.payment);

  const handlePayment = () => {
    const checkedPlan =  packages?.data?.entityList[checked];
    const paymentData = {
      package_id: checkedPlan.Identifier,
      payment_frequency: 'monthly',
    };
    dispatch(paymentAction(paymentData));
  };

  useEffect(() => {
    dispatch(getPackagesAction());
  }, [dispatch]);

  return (
    <>
      <div className='user-plan-card__header'>
        <div className='user-plan-card__header__title'>Your Plan</div>
        <div className='user-plan-card__header__subtitle'>
          Please slect a plan to unlock all your account features
        </div>
      </div>
      <div className='user-plan-card__info'>
        <div className='user-plan-card__select-list'>
          {packages?.data?.entityList?.map((card, i) => (
            <div
              className='user-plan-card__select-list__item-wrapper'
              key={card.Identifier}
            >
              <label className='user-plan-card__select-list__item'>
                <input
                  checked={i === checked}
                  type='radio'
                  name='plan'
                  value={card.Name}
                  onChange={() => setChecked(i)}
                />
                <div className='user-plan-card__select-list__item__text'>
                  <span className='user-plan-card__select-list__item__text__name'>
                    {card.Name}
                  </span>
                  <span className='user-plan-card__select-list__item__text__price'>
                    ${card.PriceMonthly}/Month
                  </span>
                  <span className='user-plan-card__select-list__item__text__cards'>
                    {card.Cards} Wyshes
                  </span>
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className='user-plan-card__cta'>
        <button
          onClick={openPaymentModal}
          className='user-plan-card__upgrade-button'
        >
          Subscribe
        </button>
      </div>
      <Modal show={show} onHide={closePaymentModal}>
        <PaymentPage handleClose={closePaymentModal} paymentData={payment} />
      </Modal>
    </>
  );
};

export default NotSubscripedUserCard;
