import {
  START_CHANGE_PROFILE_PHOTO_REQUEST,
  CHANGE_PROFILE_PHOTO_SUCCESS,
  CHANGE_PROFILE_PHOTO_FAILED,
} from '../actions/actionTypes';

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

export function changeProfilePhotoReducer(state = initialState, action) {
  switch (action.type) {
    case START_CHANGE_PROFILE_PHOTO_REQUEST:
      return { ...state, fetching: true, error: null };
    case CHANGE_PROFILE_PHOTO_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case CHANGE_PROFILE_PHOTO_FAILED:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
}
