import React, { useState, useRef } from 'react';

const classes = {
  input: {
    display: 'none',
  },
  uploadBtn: {
    width: '150px',
    height: '40px',
    borderRadius: '5px',
    background: '#ff6600',
    color: '#ffffff',
    border: 'none',
  },
};

export default function FileUploadPage({ onFileSelectSuccess }) {
  const [selectedFile, setSelectedFile] = useState();
  const fileRef = useRef(null);

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    onFileSelectSuccess(file);
    setSelectedFile(file);
  };

  return (
    <div>
      <input
        style={classes.input}
        type='file'
        name='document'
        accept='.doc,.docx,application/pdf'
        onChange={handleUploadClick}
        ref={fileRef}
      />
      <button
        style={classes.uploadBtn}
        onClick={(event) => {
          event.preventDefault();
          fileRef.current.click();
        }}
      >
        Attach document
      </button>
      <>
        {selectedFile && (
          <p>
            Filename: {selectedFile.name}
            <button
              class='file-uploader__close-button'
              onClick={() => setSelectedFile(null)}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </p>
        )}
      </>
      <></>
    </div>
  );
}
