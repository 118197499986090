import toast from 'react-hot-toast';
import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../../API/axiosClient';
import { setLocalState } from '../../../helpers/localStorage';
import {
  START_FORGETPASSWORD_REQUEST,
  FORGETPASSWORD_REQUEST_SUCCESS,
  FORGETPASSWORD_REQUEST_FAILED,
} from '../../actions/actionTypes';

export function* forgetPasswordWatcher() {
  yield takeLatest(START_FORGETPASSWORD_REQUEST, forgetPasswordWorker);
}

function forgetPassword(data) {
  return axios.post('api/forget-password', data);
}

function* forgetPasswordWorker(action) {
  try {
    const { data } = yield call(forgetPassword, action.payload);

    if (data?.errorMsg) {
      toast.error(data?.errorMsg);
    } else {
      setLocalState('@forgetToken', data);
      yield put({ type: FORGETPASSWORD_REQUEST_SUCCESS, data });
    }
  } catch (error) {
    yield put({
      type: FORGETPASSWORD_REQUEST_FAILED,
      error: error?.response?.data,
    });
  }
}
