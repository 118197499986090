import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { loginAction } from '../../../../redux/actions/Auth/login';
import { getLocalState } from '../../../../helpers/localStorage';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { Modal } from 'react-bootstrap';
import AppleLogo from '../../../../_metronic/_assets/images/app-store.png';
import GoogleLogo from '../../../../_metronic/_assets/images/google-play.png';

const initialValues = {
  email: '',
  password: '',
};

function Login(props) {
  const { intl } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const login = useSelector((state) => state.login);

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();

      const formData = new FormData();

      formData.append('Email', values.email);
      formData.append('Password', values.password);

      dispatch(loginAction(formData));

      setTimeout(() => {
        const token = !!getLocalState('@token');
        if (token) {
          window.location.pathname = '/dashboard';
        }
        disableLoading();
        setSubmitting(false);
      }, 1500);
    },
  });
  const [showIosModal, setshowIosModal] = useState(false);
  const closeIosModal = () => setshowIosModal(false);
  const openIosModal = () => setshowIosModal(true);

  const [showAndoridModal, setshowAndoridModal] = useState(false);
  const closeAndroidModal = () => setshowAndoridModal(false);
  const openAndroidModal = () => setshowAndoridModal(true);

  useEffect(()=>{
    const  isIos = /iPhone|iPad/i.test(navigator.userAgent);
    if(isIos)
    {
      setTimeout(()=>{
        openIosModal()
      }, 2000)
    }

    if (/android/i.test(navigator.userAgent)) {
      setTimeout(()=>{
        openAndroidModal()
      }, 2000)
    }
    

  }, [])
  return (
    
    <div className='login-form login-signin' id='kt_login_signin_form'>
      {/* begin::Head */}
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          Enter your email and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        {login?.error ? (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>
              {login.error.errors}
            </div>
          </div>
        ) : null}

        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Email'
            type='email'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'email'
            )}`}
            name='email'
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className='input-group fv-plugins-icon-container'>
          <input
            placeholder='Password'
            type={showPassword === true ? 'text' : 'password'}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            name='password'
            {...formik.getFieldProps('password')}
          />
          <div className='input-group-prepend'>
            <span
              className='input-group-text'
              style={{ border: 'none' }}
              onClick={() =>
                setShowPassword(showPassword === true ? false : true)
              }
            >
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Hidden.svg')} />
            </span>
          </div>
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.password}</div>
          </div>
        ) : null}
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <Link
            to='/auth/forgot-password'
            className='text-dark-50 text-hover-primary my-3 mr-2'
            id='kt_login_forgot'
          >
            <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
          </Link>
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            style={{ background: '#a1cf5f', border: '1px solid #a1cf5f' }}
          >
            <span>Sign In</span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
        <hr />
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <h2> Create New Account </h2>
          <p className='text-muted'>Don't have an account yet?</p>
          <Link
            to='/auth/registration'
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            style={{ background: '#ff6600', border: '1px solid #ff6600' }}
          >
            Sign Up
          </Link>
        </div>
      </form>
      {/*end::Form*/}
      <Modal show={showIosModal} onHide={closeIosModal} backdrop="static" className='card-ios-fixed'>
        <Modal.Body>
          <div className='row'>
            <div className='text-center'>
              <h1>For better experience , you can download the app from here</h1>
            </div>
          </div>
          <br></br>
          <div className='row text-center'>
            <div className='card-ios'>
              <a href='https://apps.apple.com/us/app/ewysh/id1594063345' rel="noreferrer noreferrer" target="_blank" >
                  <img src={AppleLogo} alt='' />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showAndoridModal} onHide={closeAndroidModal} backdrop="static" className='card-ios-fixed'>
        <Modal.Body>
        <div className='row'>
            <div className='text-center'>
              <h1>For better experience , you can download the app from here</h1>
            </div>
          </div>
          <br></br>
          <div className='row text-center'>
            <div className='card-ios'>
              <a href='https://play.google.com/store/apps/details?id=com.afterlife' rel="noreferrer noreferrer" target="_blank" >
                  <img  src={GoogleLogo} alt='' />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default injectIntl(Login);
