import React from 'react';
import NotSubscripedUserCard from './NotSubscripedUserCard';
import SubscripedUserCard from './SubscripedUserCard';
import './user-plan-card.scss';

const UserPlanCard = ({ subscribtionInfo }) => {
  const { IsSubscriped, isFree } = subscribtionInfo?.data?.entity || {};
  return (
    <div
      className={`user-plan-card ${subscribtionInfo?.data?.entity
        ?.IsNextOfKin && 'user-plan-card--small'}`}
    >
      {(IsSubscriped === 1 || isFree === 1) ? (
        <SubscripedUserCard subscribtionInfo={subscribtionInfo} />
      ) : (
        <NotSubscripedUserCard />
      )}
    </div>
  );
};

export default UserPlanCard;
