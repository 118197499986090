import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import CardPreview from '../../WyshScheduling/CardPreview';
import { previewECardAction } from '../../../../redux/actions/previewECard';
import { deleteECardAction } from '../../../../redux/actions/deleteECard';
import './scheduled-wysh-card.scss';

const ScheduledWyshCard = ({ wysh }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  console.log(wysh);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const previewECard = useSelector((state) => state.previewECard);

  const handleViewClick = (id) => {
    handleShow();
    const formData = new FormData();

    formData.append('Identifier', id);

    dispatch(previewECardAction(formData));
  };

  const handleDeleteClick = (id) => {
    swal({
      title: 'Are you sure?',
      text: 'You want to delete this card!',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'cancel_button',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'confirm_button',
          closeModal: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        const data = {
          Identifier: id,
        };
        dispatch(deleteECardAction(data));
      } else {
        return true;
      }
    });
  };

  const formatDate = (date) => {
    const isValid = !date.includes('passing');
    if (!isValid) return date;
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
    });
  };

  return (
    <>
      <div className='scheduled-card'>
        <div className='scheduled-card__info'>
          <div className='scheduled-card__info__section scheduled-card__info__section--upper'>
            <div className='scheduled-card__info__media'>
              <img
                src={
                  wysh.Media.Type === 'image'
                    ? wysh.Media.Path
                    : wysh.Media.Thumbnail
                }
                alt=''
              />
            </div>
            <div className='scheduled-card__info__data'>
              <h3 className='scheduled-card__info__data__title'>
                {wysh.FileName}
              </h3>
              <p className='scheduled-card__info__data__text'>
                Going to:&nbsp;<span>{wysh.BeneficiaryName}</span>
              </p>
              <p className='scheduled-card__info__data__text'>
                {wysh.BeneficiaryEmail}
              </p>
              <p className='scheduled-card__info__data__text'>
                Delivery Date: {formatDate(wysh.SendingDate)}
              </p>
            </div>
          </div>

          <div className='scheduled-card__info__section scheduled-card__info__section--bottom'>
            <div className='scheduled-card__info__attachment'>
              {wysh.Attachment ? (
                <>
                  <a
                    href={wysh.Attachment.Link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src='media/png/file.png' alt='' />
                  </a>
                  <p>{wysh.Attachment.Name}</p>
                </>
              ) : (
                <>
                  <img src='media/png/no-file.png' alt='' />
                  <p>No attachment</p>
                </>
              )}
            </div>
            <div className='scheduled-card__info__data'>
              <p className='scheduled-card__info__data__text'>
                Created on: {formatDate(wysh.Media.UpdatedAt)}
              </p>
              <p className='scheduled-card__info__data__text'>
                Size: {wysh.Media.Size}&nbsp; {wysh.Media.Unit}
              </p>
              <p className='scheduled-card__info__data__text'>
                Type: {wysh.Media.Type}
              </p>
            </div>
          </div>
        </div>
        <div className='scheduled-card__actions'>
          <button
            className='scheduled-card__actions__view-button'
            onClick={() => handleViewClick(wysh.Identifier)}
          >
            View
          </button>
          <button
            className='scheduled-card__actions__delete-button'
            onClick={() => handleDeleteClick(wysh.Identifier)}
          >
            Delete
          </button>
        </div>
      </div>

      <CardPreview
        show={show}
        handleClose={handleClose}
        cardData={previewECard}
      />
    </>
  );
};

export default ScheduledWyshCard;

// import { CircularProgress } from '@material-ui/core';
// import CardPreview from '../WyshScheduling/CardPreview';
// import { previewECardAction } from '../../../redux/actions/previewECard';

//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const [tooltipOpen, setTooltipOpen] = useState(false);

//   const previewECard = useSelector((state) => state.previewECard);

//   const handleViewClick = (id) => {
//     handleShow();
//     const formData = new FormData();

//     formData.append('Identifier', id);

//     dispatch(previewECardAction(formData));
//   };

//   return (
//     <div className='history-page'>
//       <button
//         className='history-page__add-wysh-button'
//         onClick={() => history.push('/cards')}
//       >
//         Add Wysh
//       </button>

//       {listCards?.data?.entityList?.length ? (
//         <div className='row'>
//           <Table striped bordered hover>
//             <thead>
//               <tr>
//                 <th>#</th>
//                 <th>Beneficiary Name</th>
//                 <th>Beneficiary Email</th>
//                 <th>Sending Date</th>
//                 <th>Status</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {listCards?.data?.entityList?.map((card, index) => {
//                 return (
//                   <tr key={card.Identifier}>
//                     <td>{index + 1}</td>
//                     <td>{card.BeneficiaryName}</td>
//                     <td>{card.BeneficiaryEmail}</td>
//                     <td>{card.SendingDate}</td>
//                     <td>
//                       <div data-tip={card.Status.Title}>
//                         <img src={card.Status.Icon} alt=''></img>
//                       </div>
//                       <ReactTooltip place='top' type='dark' effect='float'>
//                         <span>{card.Status.Title}</span>
//                       </ReactTooltip>
//                     </td>
//                     <td>
//                       <Button
//                         className='btn btn-sm btn-success'
//                         style={{
//                           background: '#ff6600',
//                           border: '1px solid #ff6600',
//                         }}
//                         onClick={() => handleViewClick(card.Identifier)}
//                       >
//                         View
//                       </Button>
//                       <Button
//                         className='btn btn-sm btn-danger mx-3'
//                         onClick={() => handleDeleteClick(card.Identifier)}
//                       >
//                         Delete
//                       </Button>
//                     </td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </Table>
//         </div>
//       ) : (
//         <div className='no-history'>
//           {listCards?.fetching === true ? (
//             <CircularProgress color='primary' />
//           ) : (
//             <div>
//               <i className='no-history-icon'>
//                 <FontAwesomeIcon icon={faCalendarAlt} />
//               </i>
//               <h2 className='no-history-heading'>No Scheduled History</h2>
//               <p className='no-history-text'>
//                 Use the "Add Wysh" button to create your Wyshes.
//               </p>
//               <p className='no-history-text'>Your Wyshes always appear here.</p>
//             </div>
//           )}
//         </div>
//       )}

//   );
// }

// export default injectIntl(HistoryList);
